import React from 'react';
import _findIndex from 'lodash/findIndex';
import _concat from 'lodash/concat';
import String from './widgets/string';
import Number from './widgets/number';
import Imagewithtext from './widgets/imagewithtext';
import Status from './widgets/status';
import Imagelist from './widgets/imagelist';
import Role from './widgets/role';
import MultipleRole from './widgets/multiplerole';
import ImagelistButton from './widgets/image-list-button';

const parseCoumns = (
  onDownload,
  enabledColumns = [],
  show,
  isFixedColumns,
  match,
  customProps,
  onRoleChange,
  onGroupManageUsers,
  onDatasourceClick
// eslint-disable-next-line max-params
) => {
  enabledColumns.map(function(colObject, index) {
    if (colObject.dataType === 'Image') {
      colObject.width = 200;
      colObject['isSort'] = false;
      colObject['className'] = 'hide';
    }
    if (colObject.dataType === 'Imagelist') {
      colObject['isSort'] = false;
    } else {
      colObject['isSort'] = true;
    }
    if (!colObject.width) {
      colObject.width = 'auto';
    }
    colObject.dataIndex = colObject.widgetId;
    colObject.key = colObject.widgetId;
    colObject.visible = show;
    colObject.ellipsis = {
      showTitle: false
    };
    if (show && isFixedColumns['status'] && isFixedColumns['columns'].indexOf(index) > -1) {
      colObject.fixed = 'left';
    }
    if (colObject.dataType !== 'Image') {
      colObject.sortDirections = ['ascend', 'descend'];
      colObject.sorter = true;
    }
    colObject.sorter = false;
    colObject.render = (text, row, index) => {
      let dataType = <span>text</span>;
      if (colObject.dataType === 'String') {
        dataType = (<String
          value={text}
          row={row}
          width={colObject.width}
          bold={colObject.bold}
          color={colObject.color}
          copy={colObject.copy}
        />);
      } else if (colObject.dataType === 'Number') {
        dataType = <Number value={text} row={row} bold={colObject.bold} color={colObject.color} />;
      } else if (colObject.dataType === 'Imagewithtext') {
        dataType = <Imagewithtext value={text} row={row} onDatasourceClick={onDatasourceClick} />;
      } else if (colObject.dataType === 'Status') {
        dataType = <Status value={text} row={row} />;
      } else if (colObject.dataType === 'Imagelist') {
        dataType = <Imagelist onDownload={onDownload} onGroupManageUsers={onGroupManageUsers} value={text} row={row} />;
      } else if (colObject.dataType === 'Role') {
        dataType = <Role value={text} row={row} />;
      } else if (colObject.dataType === 'MultipleRole') {
        dataType = <MultipleRole value={text} row={row} onRoleChange={onRoleChange} />;
      } else if (colObject.dataType === 'ImagelistButton') {
        dataType = <ImagelistButton value={text} row={row} />;
      } else {
        dataType = <span>{text}</span>;
      }
      return dataType;
    };
    return colObject;
  });
  return enabledColumns;
};

function columnsAdd(allcolumns, columnList) {
  columnList = columnList.map(function(colObject, index) {
    return colObject;
  });
  return _concat(allcolumns, columnList);
}

function hideShow(col, allColumns, allFields) {
  if (col !== 'hideall' && col !== 'showall') {
    let index = _findIndex(allColumns, function(o) { return o.id === col.Id; });
    if (index !== -1) {
      allColumns[index]['visible'] = !col.Visible;
    } else {
      allColumns[index]['visible'] = !col.Visible;
    }
  } else if (col === 'hideall') {
    allColumns = [];
  } else {
    allColumns = allFields;
    allColumns.forEach((column, index) => {
      allColumns[index]['visible'] = true;
    });
  }
  return allColumns;
}


export {
  parseCoumns, hideShow, columnsAdd
};
