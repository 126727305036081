import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const { Option } = Select;

class GzSelect extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    allowClear: PropTypes.bool,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    values: PropTypes.array,
    showSearch: PropTypes.bool,
    mode: PropTypes.string,
    tokenSeparators: PropTypes.array,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onSearch: PropTypes.func,
    labelInValue: PropTypes.bool,
    bordered: PropTypes.bool,
    onSelect: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array
    ]),
    select: PropTypes.string,
    size: PropTypes.string,
    dropdownClassName: PropTypes.string,
    dropdownRender: PropTypes.func,
    showSearch: PropTypes.bool
  }

  static defaultProps = {
    onChange: () => { },
    allowClear: false,
    disabled: false,
    loading: false,
    values: [],
    dropdownClassName: '',
    mode: '',
    tokenSeparators: [],
    placeholder: '',
    className: '',
    onFocus: () => {},
    onBlur: () => {},
    onSearch: () => {},
    labelInValue: false,
    bordered: true,
    value: undefined,
    onSelect: () => {},
    select: '',
    size: '',
    showSearch: true,
    dropdownRender: (menu) => (<div>{menu}</div>)
  }
  render() {
    let newValue = null;
    const {
      onChange,
      allowClear,
      disabled,
      loading,
      values,
      mode,
      tokenSeparators,
      placeholder,
      className,
      onFocus,
      onBlur,
      onSearch,
      labelInValue,
      bordered,
      onSelect,
      value,
      size,
      dropdownClassName,
      dropdownRender,
      showSearch
    } = this.props;
    newValue = value;
    
    return (
      <Select
        showSearch={showSearch}
        className={className}
        value={newValue}
        disabled={disabled}
        onChange={onChange}
        allowClear={allowClear}
        loading={loading}
        mode={mode}
        onFocus={onFocus}
        optionFilterProp="children"
        onBlur={onBlur}
        onSearch={onSearch}
        placeholder={placeholder}
        tokenSeparators={tokenSeparators}
        labelInValue={labelInValue}
        bordered={bordered}
        onSelect={onSelect}
        size={size}
        dropdownRender={dropdownRender}
        dropdownClassName={dropdownClassName}
        filterOption={(input, option) => {
          if (option?.key) {
            return option.key.toString().toLowerCase().includes(input.toLowerCase().toString());
          }
          return option.value.toString().toLowerCase().includes(input.toLowerCase().toString());
        }}
      >
        {values.map(value => (
          <Option
            value={value.value}
            key={value.key}
            disabled={value.disable}
          >
            {value.name}
          </Option>
        ))}
      </Select>
    );
  }
}

class GzMultiSelect extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    allowClear: PropTypes.bool,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    values: PropTypes.array,
    showSearch: PropTypes.bool,
    mode: PropTypes.string,
    tokenSeparators: PropTypes.array,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onSearch: PropTypes.func,
    labelInValue: PropTypes.bool,
    bordered: PropTypes.bool,
    onSelect: PropTypes.func,
    value: PropTypes.array
  }

  static defaultProps = {
    onChange: () => { },
    allowClear: false,
    disabled: false,
    loading: false,
    values: [],
    showSearch: false,
    mode: '',
    tokenSeparators: [],
    placeholder: '',
    className: '',
    onFocus: () => {},
    onBlur: () => {},
    onSearch: () => {},
    labelInValue: false,
    bordered: true,
    onSelect: () => {},
    value: []
  }

  render() {
    const {
      onChange,
      allowClear,
      disabled,
      loading,
      values,
      showSearch,
      mode,
      tokenSeparators,
      placeholder,
      className,
      onFocus,
      onBlur,
      onSearch,
      labelInValue,
      bordered,
      onSelect,
      value
    } = this.props;
    return (
      <Select
        showSearch={showSearch}
        className={className}
        disabled={disabled}
        onChange={onChange}
        allowClear={allowClear}
        loading={loading}
        mode={mode}
        onFocus={onFocus}
        optionFilterProp="children"
        onBlur={onBlur}
        onSearch={onSearch}
        placeholder={placeholder}
        tokenSeparators={tokenSeparators}
        labelInValue={labelInValue}
        bordered={bordered}
        onSelect={onSelect}
        value={value}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {values.map(value => (
          <Option
            value={value.value}
            key={value.key}
          >
            {value.name}
          </Option>
        ))}
      </Select>
    );
  }
}

export {
  GzSelect,
  GzMultiSelect
};
