import React from 'react';
import PropTypes from 'prop-types';
import './skeleton-loader.scss';

export function SkeletonLoader(props) {
    // Props from the parent component
    const { isLoading, children } = props;

    /* Render the JSX elements */
    return (
	<div className={`guidanz-skeleton-loader ${isLoading ? 'is-loading' : ''}`}>
		<div className='guidanz-skeleton-loader-content'>{children}</div>
	</div>
    );
}

SkeletonLoader.propTypes = {
    isLoading: PropTypes.bool,
    children: PropTypes.node
};

SkeletonLoader.defaultProps = {
    isLoading: true
};
