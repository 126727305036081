import React from 'react';
import { Collapse } from 'antd';
import PropTypes from 'prop-types';

const { Panel } = Collapse;
class GzCollapse extends React.PureComponent {
  static propTypes = {
    activeKey: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    defaultActiveKey: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array
    ]),
    bordered: PropTypes.bool,
    accordion: PropTypes.bool,
    expandIconPosition: PropTypes.string,
    destroyInactivePanel: PropTypes.bool,
    expandIcon: PropTypes.func,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    forceRender: PropTypes.bool,
    header: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.number
    ]),
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    showArrow: PropTypes.bool,
    extra: PropTypes.node,
    panel: PropTypes.array
  }

  static defaultProps = {
    activeKey: null,
    defaultActiveKey: null,
    bordered: true,
    accordion: false,
    expandIconPosition: 'left',
    destroyInactivePanel: false,
    expandIcon: () => { },
    onChange: () => { },
    disabled: false,
    forceRender: false,
    header: null,
    key: null,
    showArrow: true,
    extra: null,
    panel: []
  }

  render() {
    const {
      activeKey,
      defaultActiveKey,
      bordered,
      accordion,
      expandIconPosition,
      destroyInactivePanel,
      expandIcon,
      onChange,
      disabled,
      forceRender,
      header,
      key,
      showArrow,
      extra,
      panel
    } = this.props;
    return (
      <Collapse
        activeKey={activeKey}
        defaultActiveKey={defaultActiveKey}
        onChange={onChange}
        bordered={bordered}
        accordion={accordion}
        expandIconPosition={expandIconPosition}
        expandIcon={expandIcon}
        destroyInactivePanel={destroyInactivePanel}
      >
        <Panel
          disabled={disabled}
          forceRender={forceRender}
          header={header}
          key={key}
          showArrow={showArrow}
          extra={extra}
        >
          {panel.map(item => (
            <p key={item.header}>{item.header}</p>
          ))}
        </Panel>
      </Collapse>


    );
  }
}

export default GzCollapse;
