import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './role.module.scss';
import { GzIcon } from 'library/shared/widgets';

export default class TBLRole extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object
    ]),
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    isEdit: PropTypes.bool
  }
  static defaultProps = {
    value: '',
    isEdit: true
  }
  
  
  render() {
    let { value } = this.props;
    return (
      <React.Fragment>
        <div className={styles.roleDesign}>
          {(value === "User") ?
            <>
              <GzIcon iconClassName={styles.icons} name="user"/>
              <span>User</span>
            </> :
            <>
              <GzIcon iconClassName={styles.icons} name="star"/>
              <span> {value}</span>
            </>
          }
        </div>
      </React.Fragment>
    );
  }
}

export { TBLRole };
