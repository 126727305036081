import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Input, DatePicker } from 'antd';

class GzInput extends PureComponent {
  static propTypes = {
    placeholder: PropTypes.string,
    size: PropTypes.string,
    id: PropTypes.string,
    prefix: PropTypes.node,
    suffix: PropTypes.node,
    type: PropTypes.string,
    autosize: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    allowClear: PropTypes.bool,
    addonAfter: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    addonBefore: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onPressEnter: PropTypes.func,
    maxLength: PropTypes.number,
    min: PropTypes.number,
    className: PropTypes.string,
    // defaultValue: PropTypes.string,
    name: PropTypes.string
  }

  static defaultProps = {
    placeholder: '',
    size: '',
    id: '',
    prefix: null,
    suffix: null,
    type: null,
    autosize: false,
    allowClear: false,
    addonAfter: '',
    addonBefore: '',
    value: '',
    maxLength: null,
    min: 0,
    onChange: () => { },
    onBlur: () => { },
    onPressEnter: () => { },
    className: '',
    // defaultValue: '',
    disabled: false,
    name: ''
  }

  render() {
    const {
      placeholder,
      size,
      // id,
      prefix,
      suffix,
      type,
      autosize,
      allowClear,
      addonAfter,
      addonBefore,
      value,
      maxLength,
      min,
      onChange,
      onBlur,
      onPressEnter,
      className,
      // defaultValue,
      disabled,
      name
    } = this.props;
    return (
      <Input
        placeholder={placeholder}
        size={size}
        // id={id}
        addonAfter={addonAfter}
        prefix={prefix}
        suffix={suffix}
        onChange={onChange}
        onBlur={onBlur}
        onPressEnter={onPressEnter}
        type={type}
        value={value}
        addonBefore={addonBefore}
        autosize={autosize.toString()}
        allowClear={allowClear}
        max={maxLength}
        min={min}
        className={className}
        // defaultValue={defaultValue}
        disabled={disabled}
        name={name}
      />
    );
  }
}

class GzPassword extends PureComponent {
  static propTypes = {
    placeholder: PropTypes.string,
    size: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    visibilityToggle: PropTypes.bool,
    value: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    placeholder: '',
    size: '',
    onChange: () => { },
    className: '',
    visibilityToggle: true,
    disabled: false,
    value: ''
  }

  render() {
    const {
      placeholder,
      size,
      onChange,
      className,
      name,
      disabled,
      visibilityToggle,
      value
    } = this.props;
    return (
      <Input.Password
        placeholder={placeholder}
        size={size}
        name={name}
        disabled={disabled}
        value={value}
        onChange={onChange}
        className={className}
        visibilityToggle={visibilityToggle}
      />
    );
  }
}

class GzInputSearch extends PureComponent {
  static propTypes = {
    onSearch: PropTypes.func,
    enterButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    loading: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string
  }

  static defaultProps = {
    onSearch: () => { },
    onChange: () => { },
    enterButton: false,
    loading: false,
    type: '',
    value: '',
    placeholder: 'search',
    className: ''
  }

  render() {
    const { Search } = Input;
    const {
      onSearch,
      onChange,
      enterButton,
      loading,
      type,
      value,
      placeholder,
      className
    } = this.props;
    return (
      <Search
        className={className}
        onSearch={onSearch}
        enterButton={enterButton}
        loading={loading}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    );
  }
}

class GzTextArea extends PureComponent {
  static propTypes = {
    rows: PropTypes.string,
    allowClear: PropTypes.bool,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
  }

  static defaultProps = {
    rows: '',
    allowClear: false,
    className: '',
    placeholder: '',
    onChange: () => { }
  }

  render() {
    const { TextArea } = Input;
    const {
      rows,
      allowClear,
      className,
      placeholder,
      value,
      onChange
    } = this.props;
    return (
      <TextArea
        placeholder={placeholder}
        className={className}
        rows={rows}
        allowClear={allowClear}
        value={value}
        onChange={onChange}
      />
    );
  }
}

class GzInputDate extends PureComponent {
  render() {
    return (
      <DatePicker style={{ width: '75%' }} />
    );
  }
}


export {
  GzInput,
  GzPassword,
  GzInputSearch,
  GzTextArea,
  GzInputDate
};
