import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Popconfirm } from 'antd';
import { GzIcon } from '../icon';

class GzPopconfirm extends PureComponent {
  static propTypes = {
    cancelText: PropTypes.string,
    okText: PropTypes.string,
    okType: PropTypes.string,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    icon: PropTypes.node,
    disabled: PropTypes.bool,
    children: PropTypes.node
  }

  static defaultProps = {
    cancelText: 'Cancel',
    okText: 'OK',
    okType: 'primary',
    title: null,
    onCancel: () => { },
    onConfirm: () => { },
    icon: <GzIcon name="ico_star" />,
    disabled: false,
    // placement: '',
    children: null
  }

  render() {
    const {
      cancelText,
      okText,
      okType,
      title,
      onCancel,
      onConfirm,
      disabled,
      children
    } = this.props;
    return (
      <Popconfirm
        title={title}Icon
        onConfirm={onConfirm}
        onCancel={onCancel}
        okText={okText}
        cancelText={cancelText}
        okType={okType}
        disabled={disabled}
      // icon={icon}
      >
        {children}
      </Popconfirm>
    );
  }
}

export default GzPopconfirm;
