import React from 'react';
import { GzIcon, GzButton } from 'library/shared/widgets';
import './pageNotFound.scss';
import { useHistory } from 'react-router-dom';

export function PageNotFound() {
    /* Render the JSX elements */
  const history = useHistory();
    
  return (
    <div className="pageNotFound">
      <GzIcon name="404" />
      <h1>404 - Page not found</h1>
      <p>The page you are looking for may have been deleted or is temporarily unavailable </p>
      <div className="home-button">
        <GzButton name="Go back to home" onClick={() => history.push('/home')}/>
      </div>
    </div>
  );
}
