import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown, Button
} from 'antd';
import { GzIcon } from '../icon';
import { GzMenu } from '../menu';

export class GzDropDown extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    placement: PropTypes.string,
    trigger: PropTypes.string,
    visible: PropTypes.bool,
    onVisibleChange: PropTypes.func,
    type: PropTypes.string,
    menus: PropTypes.array,
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onClick: PropTypes.func,
    icon: PropTypes.string,
    className: PropTypes.string,
    menuclassName: PropTypes.string,
    menuType: PropTypes.string,
    onClickMenu: PropTypes.func,
    menuIcon: PropTypes.string,
    menuIconClassName: PropTypes.string,
    menuItemClassName: PropTypes.string,
    popupClassName: PropTypes.string
  }

  static defaultProps = {
    disabled: false,
    placement: 'bottomRight',
    trigger: 'hover',
    visible: true,
    onVisibleChange: () => { },
    type: '',
    menus: [],
    title: null,
    onClick: () => { },
    icon: null,
    className: '',
    menuclassName: '',
    menuType: 'vertical',
    onClickMenu: () => {},
    menuIcon: 'menuMeatballFill',
    menuIconClassName: '',
    menuItemClassName: '',
    popupClassName: ''
  }

  render() {
    const {
      disabled,
      placement,
      trigger,
      type,
      menus,
      title,
      onClick,
      className,
      menuclassName,
      menuType,
      onClickMenu,
      menuIcon,
      menuIconClassName,
      menuItemClassName,
      popupClassName
    } = this.props;
    const menu = (
      <GzMenu
        popupClassName={popupClassName}
        onClick={onClickMenu}
        className={menuclassName}
        menus={menus}
        mode={menuType}
        inlineCollapsed={false}
        menuItemClassName={menuItemClassName}
      />
    );

    return (
      <React.Fragment>
        {type === 'dropdownButton' ?
          (
            <Dropdown.Button
              onClick={onClick}
              // icon={icon}
              overlay={menu}
              trigger={[trigger]}
              className={className}
            >
              Dropdown
            </Dropdown.Button>
          ) :
          (
            <Dropdown
              overlay={menu}
              trigger={[trigger]}
              placement={placement}
              disabled={disabled}
              className={className}
              onClick={onClick}
            >
              {type === 'button' ?
                <Button>{title}</Button> :
                (
                  <GzIcon name={menuIcon} className={menuIconClassName} />
                )
              }
            </Dropdown>
          )
        }

      </React.Fragment>
    );
  }
}
