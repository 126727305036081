/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';

import { Subject } from 'rxjs';
import {
  getAuthenticationTokens,
  setAuthenticationTokens,
  removeAuthenticationTokens
} from '../../../common/helpers/http.helper';
import { STORAGE } from 'library/common/constants';

/* Subject object for handling events */

export const onAuthenticationChange$ = new Subject();
export const onUserAvatarChange$ = new Subject();
export const onUserRoleChange$ = new Subject();
export const onLogoutChange$ = new Subject();
export const onEmailVerified$ = new Subject();

/* Authentication store to keep tracking of logged in status */

export const AuthenticationStore = {
  isLoggedIn: !!getAuthenticationTokens(),

  login(token) {
    setAuthenticationTokens(token);
    this.isLoggedIn = true;
    onAuthenticationChange$.next(this.isLoggedIn);
  },

  logout(canLogOut = true) {
    if (this.isLoggedIn && canLogOut) {
      removeAuthenticationTokens();
      this.isLoggedIn = false;
      onAuthenticationChange$.next(this.isLoggedIn);
      onLogoutChange$.next(true);
    }
    if (!canLogOut) {
      onAuthenticationChange$.next(false);
    }
  }
};

/* Wrapper Route component for handling authentication */

export function AuthenticationRoute({ component: Component, ...rest }) {
  const page = 'signin';
  const history = useHistory();

  if(getAuthenticationTokens()) {
    let continueUrl = localStorage.getItem(STORAGE.GO_BACK_URL);
    if(continueUrl) {
      localStorage.setItem(STORAGE.GO_BACK_URL, "");
      history.push(continueUrl);
    }
  } else {
    localStorage.setItem(STORAGE.GO_BACK_URL, history.location.pathname);
    history.push('/authentication/signin');
  }
  return (
    <Route
      {...rest}
      render={() => (
        AuthenticationStore.isLoggedIn ? <Component /> :
          <Redirect to={`/authentication/${page}`} />
      )}
    />
  );
}
