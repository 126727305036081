import { STORAGE } from '../constants/storage.constant';

/* Get stored authentication tokens from storage */
export const getAuthenticationTokens = () => {
  try {
    if (localStorage.getItem(STORAGE.AUTHENTICATION_TOKENS)) {
      return {
        [STORAGE.AUTHENTICATION_TOKENS]: JSON.parse(localStorage.getItem(STORAGE.AUTHENTICATION_TOKENS))
      };
    }
  } catch (err) {
    console.error(err);
  }
  return false;
};

/* Store the authentication token in stroage */
export const setAuthenticationTokens = (token) => {
  localStorage.setItem(STORAGE.AUTHENTICATION_TOKENS, JSON.stringify(token));
};

/* Remove the authentication token from storage */
export const removeAuthenticationTokens = () => {
  localStorage.removeItem(STORAGE.AUTHENTICATION_TOKENS);
  localStorage.removeItem(STORAGE.USER_INFO);
};
