import { config } from 'config';
import { STORAGE } from '../constants/storage.constant';
import { Logger, toast } from '../utils';
import { getAuthenticationTokens } from '../helpers/http.helper';
import { AuthenticationStore } from 'library/shared/components';
import jwt from 'jsonwebtoken';

export const HttpService = {

  get: (url, options = {}) => {
    options.method = 'GET';
    return sendRequest(url, options);
  },
  post: (url, options = {}) => {
    options.method = options.method || 'POST';
    return sendRequest(url, options);
  },
  update: (url, options = {}) => {
    options.method = 'PUT';
    return sendRequest(url, options);
  },
  patch: (url, options = {}) => {
    options.method = 'PATCH';
    return sendRequest(url, options);
  },
  delete: (url, options = {}) => {
    options.method = 'DELETE';
    return sendRequest(url, options);
  },
  postq: (url, options = {}, callback = () => false, queueName = 'default') => {
    let queue = Queue.getQueue(queueName);
    options.method = 'POST';
    return queue.queueRequest(url, options, callback);
  },
  fetch: (url, options = {}) => {
    options = Object.assign({ method: 'GET' }, options);
    let request = createRequest(url, options);
    return fetch(request).then(checkStatus);
  },
  upload: (url, options = {}) => {
    let request = createRequest(url, options);
    return fetch(request).then(checkStatus)
      .catch(err => {
        return Promise.reject(err);
      });
  }
};

/* Send request to the server */
const sendRequest = (url, options) => {
  url = `${config().API_SERVER_URL}${url}`;
  const request = createRequest(url, options);
  const promise = fetch(request)
    .then(checkStatus)
    .then(response => response.json())
    .catch(err => {
      // if (options.method !== 'GET') {
      const showToast = options.hasOwnProperty('toast') ? options.toast : true;
      if (err.message === 'Token Expired' || err.message === 'Invalid Token') {
        AuthenticationStore.logout(true);
        return;
      }
      showToast && toast.error(err);
      // }
      return Promise.reject(err);
    });

  return promise;
};

/* Create the http request based on options */
const createRequest = (url, options) => {
  let headers = {};

  if ((options?.header && !options.header.hasOwnProperty('Content-Type')) || (!options?.hasOwnProperty('header'))) {
    headers = Object.assign({ 'Content-Type': 'application/json' }, options.header || {});
  }

  const authenticationTokens = getAuthenticationTokens();

  if (authenticationTokens) {
    headers['Authorization'] = authenticationTokens[STORAGE.AUTHENTICATION_TOKENS];
    headers['X-Csrf-Token'] = jwt.decode(authenticationTokens[STORAGE.AUTHENTICATION_TOKENS]).Csrf;
  }
  headers['Account-ID'] = 'ac_e8e02e6e5e';
  if (options?.query) {
    url = serializeQueryParams(url, options.query);
  }

  const request = {
    method: options?.method,
    headers: headers,
    credentials: options?.isCORS ? 'include' : 'same-origin' // Mandatory for sending cookie along with request
  };

  if (options?.data) {
    request['body'] = (typeof options.data !== 'string') ? JSON.stringify(options.data) : options.data;
  }

  if (options?.body) {
    request['body'] = options.body;
  }

  if (options?.signal) {
    request['signal'] = options.signal;
  }

  return new Request(url, request);
};

/* Combine url with query params */
const serializeQueryParams = (url, params = {}) => {
  let queryString = url.lastIndexOf('?') !== -1 ? '&' : '?';

  for (let key in params) {
    if (params.hasOwnProperty(key)) {
      queryString += `${key}=${encodeURIComponent(params[key])}`;
    }
  }

  return `${url}${queryString}`;
};

/* Check http response status */
const checkStatus = async(response) => {
  if (response.status >= 200 && response.status < 300) {
    if (response.headers.get('Authorization')) {
      localStorage.setItem(STORAGE.AUTHENTICATION_TOKENS, JSON.stringify(response.headers.get('Authorization')));
    }
    return response;
  } else {
    const res = await response.json();

    if (res.Status >= 400 && res.Message !== '') {
      return Promise.reject({ message: res.Message, error_code: res.StatusCode });
    }

    try {
      Logger.warn('Network Request Failed',
        { url: response.url, status: response.status, statusText: response.statusText }
      );

      if (response.headers.get('Content-Type') === 'application/json') {
        return response.json().then(json => Promise.reject(json));
      }

      return Promise.reject({ errorMessage: 'Unhandled server error' });
    } catch (err) {
      Logger.error(err);
      return Promise.reject({ status: response.statusText });
    }
  }
};


/* Internal queue class */
class Queue {
    static requestQueue = {};

    static getQueue(name = 'system') {
      if (!this.requestQueue[name]) {
        this.requestQueue[name] = new Queue(name);
      }
      return this.requestQueue[name];
    }

    constructor(name) {
      this.name = name;
      this.requestQueue = [];
      this.executeQueue = this.executeQueue.bind(this);
    }

    queueRequest(url, option, callback) {
      let queueId = new Date().getTime();
      this.requestQueue.push({
        id: queueId,
        url,
        option,
        callback
      });
        //trigger the queue
      this.executeQueue();
    }

    executeQueue(updatedRequestData = null) {
      if (this.requestStatus === 'waiting') {
            //break the recurrsion;
        return;
      }
      let requestObj = this.requestQueue.shift();
      if (!requestObj) {
        return;
      }
        //if data is present then update request object with latest data
      if (updatedRequestData) {
        requestObj.option.data = updatedRequestData;
      }

      this.requestStatus = 'waiting';
      let res = null;
      let err = null;

      sendRequest(requestObj.url, requestObj.option)
        .then((data) => {
          res = data;
        }, (error) => {
          err = error;
        })
        .finally(() => {
          this.requestStatus = 'done';
          let shouldWait = (data) => {
                    //proceed to next request
            this.executeQueue(data);
          };
                //check if the waiting callback method is accepted in the given callback
                //if so then wait untill callback is called, then proceed with queue execution
          if (requestObj.callback.length === 3) {
            requestObj.callback(err, res, shouldWait);
          } else {
            requestObj.callback(err, res);
                    //proceed to next request
            this.executeQueue();
          }
        });
    }

    clear() {
      if (this.name === 'system') {
        return;
      }
      Reflect.deleteProperty(Queue.requestQueue, this.name);
    }
}
