import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import momentPropTypes from 'react-moment-proptypes';

class GzDatePicker extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    disabledDate: PropTypes.func,
    mode: PropTypes.string,
    open: PropTypes.bool,
    placeholder: PropTypes.string,
    size: PropTypes.string,
    onOpenChange: PropTypes.func,
    onPanelChange: PropTypes.func,
    format: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array
    ]),
    showTime: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number
    ]),
    showToday: PropTypes.bool,
    value: momentPropTypes.momentObj,
    onChange: PropTypes.func,
    onOk: PropTypes.func,
    defaultValue: momentPropTypes.momentObj
  }

  static defaultProps = {
    className: '',
    disabled: false,
    disabledDate: () => { },
    mode: 'date',
    open: false,
    placeholder: '',
    size: '',
    onOpenChange: () => { },
    onPanelChange: () => { },
    format: 'YYYY-MM-DD',
    showTime: null,
    showToday: true,
    value: null,
    onChange: () => { },
    onOk: () => { },
    defaultValue: null
  }

  render() {
    const {
      className,
      disabled,
      disabledDate,
      mode,
      open,
      placeholder,
      size,
      onOpenChange,
      onPanelChange,
      format,
      showTime,
      showToday,
      value,
      onChange,
      onOk,
      defaultValue
    } = this.props;
    return (
      <DatePicker
        className={className}
        disabled={disabled}
        disabledDate={disabledDate}
        mode={mode}
        format={format}
        placeholder={placeholder}
        onChange={onChange}
        open={open}
        onOpenChange={onOpenChange}
        onPanelChange={onPanelChange}
        onOk={onOk}
        size={size}
        showToday={showToday}
        showTime={showTime}
        defaultValue={defaultValue}
        value={value}
      />

    );
  }
}

export default GzDatePicker;
