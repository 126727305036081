import React, { lazy, Suspense, useReducer } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Loader, ErrorBoundary, CobHeader } from 'library/shared/components';
import { storeReducer, initialStoreState, StoreContext } from 'Store.context';

/* Lazy loaded content for code splitting */
const Cob = lazy(() => import('views/Cob'));

/* Router configuration component */
export function Router() {
    // Reducer instance for store
  const [storeState, storeDispatch] = useReducer(storeReducer, initialStoreState);

  return (
    <BrowserRouter basename="app">
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          {/* Place the loader as element for handling screen rendering delay */}
          <Loader manual={true} />
          <StoreContext.Provider value={{ storeState: storeState, storeDispatch: storeDispatch }}>
            <CobHeader />
            <Switch>
              <Route path="/" render={() => <Cob />} />
            </Switch>
          </StoreContext.Provider>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter >
  );
}
