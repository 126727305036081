import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Upload, Modal } from 'antd';
import { GzButton } from '../button';
import { GzIcon } from '../icon';
export class GzUpload extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    directory: PropTypes.bool,
    listType: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string,
    onPreview: PropTypes.func,
    onChange: PropTypes.func,
    previewVisible: PropTypes.bool,
    previewTitle: PropTypes.string,
    previewImage: PropTypes.string,
    fileList: PropTypes.array,
    className: PropTypes.string
  }

  static defaultProps = {
    directory: false,
    listType: 'picture-card',
    name: 'file',
    text: '',
    icon: '',
    onPreview: () => {},
    onChange: () => {},
    previewVisible: false,
    previewTitle: '',
    previewImage: '',
    fileList: [],
    className: ''
  }

  render() {
    const {
      directory,
      listType,
      text,
      icon,
      onPreview,
      onChange,
      previewVisible,
      previewTitle,
      previewImage,
      fileList,
      className
    } = this.props;
    const uploadButton = <GzButton type="link" name={text}/>;

    return (
      <React.Fragment>
        <Upload
          listType={listType}
          className={`${className} avatar-uploader`}
          showUploadList={false}
          directory={directory}
          onPreview={onPreview}
          onChange={onChange}
        >
          { previewImage ?
            <img src={previewImage} alt="avatar" style={{ width: '100%' }} /> :
            <span>
              {icon !== '' ? <GzIcon name={icon} /> : null}
              {(fileList.length > 0) ? null : uploadButton}
            </span>
          }
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={onPreview}
        >
          <img alt="img" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </React.Fragment>
    );
  }
}


export class GzUploadAvatar extends PureComponent {
  static propTypes = {
    listType: PropTypes.string,
    onChange: PropTypes.func,
    imageUrl: PropTypes.string,
    loading: PropTypes.bool,
    action: PropTypes.string,
    name: PropTypes.string,
    multiple: PropTypes.bool
  }

  static defaultProps = {
    listType: 'text',
    onChange: () => { },
    imageUrl: '',
    loading: false,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    name: 'avatar',
    multiple: false
  }

  render() {
    const {
      listType,
      onChange,
      imageUrl,
      loading,
      action,
      name,
      multiple
    } = this.props;

    const uploadButton = (
      <div>
        {loading ? <GzIcon name="edit1" /> : <GzIcon name="edit1" />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <Upload
        name={name}
        showUploadList={false}
        action={action}
        className="avatar-uploader"
        onChange={onChange}
        listType={listType}
        multiple={multiple}>
        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    );
  }
}


export class GzDragFiles extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    multiple: PropTypes.bool,
    action: PropTypes.string,
    onChange: PropTypes.func
  }

  static defaultProps = {
    name: 'file',
    multiple: false,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange: () => { }
  }

  render() {
    const { Dragger } = Upload;
    const {
      name,
      multiple,
      action,
      onChange
    } = this.props;
    return (
      <Dragger name={name} multiple={multiple} action={action} onChange={onChange}>
        <p className="ant-upload-drag-icon">
          {' '}
          <GzIcon name="ico_upload" />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload.
          Strictly prohibit from uploading company data or other band files
        </p>
      </Dragger>
    );
  }
}
