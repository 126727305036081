import React, { PureComponent } from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';


export class GzRadio extends PureComponent {
  static propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func,
    size: PropTypes.string,
    className: PropTypes.string
  }

  static defaultProps = {
    options: [],
    onChange: () => { },
    size: '',
    className: ''
  }

  render() {
    const {
      options,
      size,
      onChange,
      className
    } = this.props;
    return (
      <Radio.Group onChange={onChange} className={className}>
        {options.map(val => (
          <Radio
            disabled={val.disabled}
            value={val.value}
            size={size}
            key={val.name}
          >
            {val.name}
          </Radio>
        ))
        }
      </Radio.Group>
    );
  }
}
