import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Isvg from 'react-inlinesvg';
import styles from './icon.module.scss';

export class GzIcon extends PureComponent {
  static propTypes = {
    size: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    name: PropTypes.string.isRequired,
    boxClassName: PropTypes.string,
    iconClassName: PropTypes.string,
    hover: PropTypes.bool,
    onClick: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.bool
    ]),
    btnIcon: PropTypes.bool,
    title: PropTypes.string

  }

  static defaultProps = {

    size: null,
    boxClassName: '',
    hover: false,
    onClick: () => { },
    title: '',
    iconClassName: '',
    name: 'Error',
    btnIcon: false
  }

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(evt) {
    if (this.props.onClick) {
      typeof this.props.onClick === 'function' && this.props.onClick(evt);
    }
  }

  componentDidCatch(err) {
    console.error('Icon catch', err);
  }

  render() {
    const {
      size, btnIcon, name, boxClassName = '', iconClassName = '', onClick, hover, ...restProps
    } = this.props;
    try {
      return (
        <span
          title={this.props.title}
          className={`${styles.iconContainer} ${(onClick || hover) ? styles.pointer : ''}
          ${boxClassName} ${btnIcon ? styles.btnIcon : ''}`}
          onClick={this.onClick}
          {...restProps}
        >
          <Isvg
            className={`${iconClassName} ${styles.iconSvg}`}
            src={require(`./icons/${name || 'ico_small_tick'}.svg`)}
            cachegetrequests="true"
          />
        </span>
      );
    } catch (e) {
      const err = `Icon Error: ${(!name) ? 'name is missing in prop' : e.message}`;
      console.error(err);
      return <span title={err}>icon error</span>;
    }
  }
}
