import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';


class GzCard extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.array
    ]),
    loading: PropTypes.bool,
    cover: PropTypes.element,
    actions: PropTypes.array,
    cardtitle: PropTypes.string,
    bordered: PropTypes.bool,
    className: PropTypes.string,
    key: PropTypes.string,
    hoverable: PropTypes.bool
  }

  static defaultProps = {
    children: null,
    key: '',
    loading: false,
    bordered: false,
    cover: null,
    actions: [],
    cardtitle: '',
    className: '',
    hoverable: true
  }

  render() {
    const {
      children,
      loading,
      cover,
      actions,
      cardtitle,
      bordered,
      className,
      key,
      hoverable
    } = this.props;
    return (
      <Card
        loading={loading}
        hoverable={hoverable}
        key={key}
        cover={cover}
        actions={actions}
        title={cardtitle}
        bordered={bordered}
        className={className}
      >
        {children}
      </Card>
    );
  }
}

export { GzCard };
