import { Subject } from 'rxjs';

// Subject for notifiy sorting clicks
export const onClickHead$ = new Subject();

/* Expose the subject as observable */
export const onClickHead = () => onClickHead$.asObservable();

// Subject for notifiy the changes in selected rows
export const onChangeSelectedRows$ = new Subject();

/* Expose the subject as observable */
export const onChangeSelectedRows = () => onChangeSelectedRows$.asObservable();
