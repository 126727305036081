import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

export default class GzTooltip extends PureComponent {
   static propTypes = {
     title: PropTypes.oneOfType([
       PropTypes.string,
       PropTypes.node
     ]),
     placement: PropTypes.string,
     trigger: PropTypes.string,
     visible: PropTypes.bool,
     children: PropTypes.node
   }

   static defaultProps = {
     title: null,
     placement: 'top',
     trigger: '',
     visible: false,
     children: null
   }

   render() {
     const {
       title,
       placement,
       trigger,
       visible,
       children
     } = this.props;
     return (
       <Tooltip title={title} placement={placement} visible={visible} trigger={trigger}>
         {children}
       </Tooltip>
     );
   }
}
