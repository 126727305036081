import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './imagelist.module.scss';
import { GzButton, GzIcon } from 'library/shared/widgets';

export default class TBLImagelistButton extends Component {
  static propTypes = {
    items: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
      PropTypes.array
    ]),
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    isEdit: PropTypes.bool,
    row: PropTypes.object,
    value: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
    ]),
    onDownload: PropTypes.func
  }

  static defaultProps = {
    items: [],
    isEdit: true,
    row: {},
    value: null,
    onDownload: () => { }
  }

  render() {
    const { value } = this.props;
    return (
      <div className={styles.imagelistDesign}>
        {value &&
          <React.Fragment>
            {JSON.parse(value).map((item, i) =>
              (i < 6) &&
              (item.imageUrl === "null" ?
                (
                  <div className={styles.imageItem} key={i}><GzIcon name="defaultOrg" /></div>
                ) :
                <div className={styles.imageItem} key={i}>
                  <img className={`${i !== 0 ? styles.img : ''}`} src={item.imageUrl} alt="" />
                </div>)
            )}

            {JSON.parse(value).length > 6 && <GzButton className='group-user-btn' name='+11 User(s)' type='link' />}
          </React.Fragment>
        }
      </div>

    );
  }
}

export { TBLImagelistButton };

