/* eslint-disable no-sequences */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Resizable } from 'react-resizable';
import { Table } from 'antd';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import { GzIcon, GzSelect } from 'library/shared/widgets';
import { parseCoumns, hideShow, columnsAdd } from './constant';


import styles from './datatable.module.scss';
import './table.scss';

const ResizeableTitle = props => {
    // eslint-disable-next-line react/prop-types
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class DataTable extends Component {
    static propTypes = {
      toolbarName: PropTypes.string,
      className: PropTypes.string,
      nextCls: PropTypes.string,
      search: PropTypes.func,
      filterData: PropTypes.any,
      columnsData: PropTypes.array,
      onRow: PropTypes.func,
      onRowEnter: PropTypes.func,
      onRowHoverLeave: PropTypes.func,
      onRoleChange: PropTypes.func,
      tableData: PropTypes.object,
      isFixedColumns: PropTypes.object,
      isSelection: PropTypes.object,
      paginationName: PropTypes.string,
      toolbar: PropTypes.bool,
      customCol: PropTypes.array,
      customToolbar: PropTypes.any,
      filter: PropTypes.bool,
      hideShow: PropTypes.bool,
      match: PropTypes.bool,
      customProps: PropTypes.any,
      pagination: PropTypes.bool,
      isResize: PropTypes.bool,
      header: PropTypes.bool,
      rowKey: PropTypes.string,
      onDownload: PropTypes.func,
      onGroupManageUsers: PropTypes.func,
      onDatasourceClick: PropTypes.func,
      tableLoading: PropTypes.bool
    };
    static defaultProps = {
      isSelection: {
        select: true,
        rowSelectionFunction: () => { },
        selectedRowKeys: [],
        disableCheckBox: {}
      },
      onRow: () => { },
      onRowEnter: () => { },
      onRowHoverLeave: () => { },
      onRoleChange: () => { },
      isFixedColumns: { status: true, columns: [0] },
      paginationName: 'Applications',
      toolbar: true,
      filter: true,
      hideShow: true,
      pagination: true,
      isResize: true,
      header: true,
      tableLoading: false
    }
    constructor(props) {
      super(props);
      let columns = parseCoumns(
        this.props.onDownload,
        props.tableData['columns'],
        true,
        this.props.isFixedColumns,
        this.props.match,
        this.props.customProps,
        this.props.onRoleChange,
        this.props.onGroupManageUsers,
        this.props.onDatasourceClick
      );
      if (props.customCol) {
        columns = columnsAdd(columns, props.customCol);
      }
      this.state = {
        isOpen: false,
        filteredColumns: [...columns],
        columns,
        isFilter: false,
        allColumns: [...columns],
        rows: this.props.tableData?.data || [],
        originalRows: this.props.tableData?.data || [],
        pagination: {
          currentPage: 1,
          pageSize: 10,
          totalCount: 0,
          totalPage: 0,
          skip: 0
        },
        pageSize: [{
          value: 10,
          name: '10',
          key: '10'
        }, {
          value: 25,
          name: '25',
          key: '25'
        }, {
          value: 50,
          name: '50',
          key: '50'
        }, {
          value: 100,
          name: '100',
          key: '100'
        }],
        sortOrder: 'no',
        sortId: null
      };
      this.searchColumnsAll = this.searchColumnsAll.bind(this);
      this.searchRows = this.searchRows.bind(this);
      this.isColActive = this.isColActive.bind(this);
      this.applySearchRowsData = this.applySearchRowsData.bind(this);
      this.paginationCalc();
    }


    components = {
      header: {
        cell: ResizeableTitle
      }
    };

    handleResize = index => (e, { size }) => {
      this.setState(({ columns }) => {
        const nextColumns = [...columns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width
        };
        return { columns: nextColumns };
      });
    };


    updateFilter = () => {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }

    handleHideColumns = (col) => {
      let columnsResult = hideShow(col, this.state.allColumns, this.state.filteredColumns);
      const addedcolumns = columnsResult.flatMap((bool, index) => bool.visible ? columnsResult[index] : []);
      let columns = addedcolumns;
      this.setState({
        filteredColumns: columnsResult,
        columns
      });
    }

    sortBy = (field, reverse, primer) => {
      const key = primer ?
        function(x) {
          return primer(x[field]);
        } :
        function(x) {
          return x[field];
        };

      reverse = !reverse ? 1 : -1;

      return function(a, b) {
            // eslint-disable-next-line no-return-assign
        return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
      };
    }

    sortObj(list, key, desc) {
      function compare(a, b) {
        a = a[key];
        b = b[key];
        if (Array.isArray(b)) {
          a = a.length;
        }
        if (Array.isArray(b)) {
          b = b.length;
        }
        var type = (typeof (a) === 'string' ||
                typeof (b) === 'string') ? 'string' : 'number';
        var result;
        if (type === 'string') {
          result = a.localeCompare(b);
        } else if (desc) {
          result = b - a;
        } else {
          result = a - b;
        }
        return result;
      }
      return list.sort(compare);
    }

    onChangeHeader = (sorter) => {
      let rows = this.state.rows;
        // let col = _find(this.state.allColumns, function(o) {
        //   return o['widgetId'] === sorter['columnKey'];
        // });
      if (this.state['sortOrder'] === 'no' || this.state['sortOrder'] === 'ascend') {
        if (sorter['dataType'] === "Amount" ||
                sorter['dataType'] === "Number" ||
                sorter['dataType'] === "Link" ||
                sorter['dataType'] === "Double") {
          rows = this.sortObj(rows, sorter['widgetId'], false);
        } else {
          rows = rows.sort(this.sortBy(sorter['widgetId'], false, (obj) => {
            let val = obj ? obj : '';
            if (Number.isInteger(val)) {
              val = val.toString();
            }
            if (Array.isArray(obj)) {
              val = val.length.toString();
            }
            if (((typeof val === 'number') && (val % 1 !== 0))) {
              val = val.toString();
            }
            if (val === true) {
              val = val.toString();
            }
            return val.toUpperCase();
          }));
        }
        this.setState({
          sortOrder: "descend",
          sortId: sorter['id']
        });
      } else if (this.state['sortOrder'] === 'descend') {
        if (sorter['dataType'] === "Amount" ||
                sorter['dataType'] === "Number" ||
                sorter['dataType'] === "Link" ||
                sorter['dataType'] === "Double") {
          rows = this.sortObj(rows, sorter['widgetId'], true);
        } else {
          rows = rows.sort(this.sortBy(sorter['widgetId'], true, (obj) => {
            let val = obj ? obj : '';
            if (Number.isInteger(val)) {
              val = val.toString();
            } if (Array.isArray(obj)) {
              val = val.length.toString();
            }
            if (((typeof val === 'number') && (val % 1 !== 0))) {
              val = val.toString();
            }
            if (val === true) {
              val = val.toString();
            }
            return val.toUpperCase();
          }));
        }
        this.setState({
          sortOrder: "ascend",
          sortId: sorter['id']
        });
      }
      this.setState({
        rows
      });
      this.paginationCalc(rows);
    }

    isColActive(col) {
      const { columns } = this.state;
      return columns.filter(
        item => item.widgetId === col.widgetId
      )[0].active;
    }

    applySearchRowsData(text, rows) {
      const { columns } = this.state;
      return rows.filter(row => {
        let hasText = false;
        const keys = Object.keys(row);
        const columnIds = columns.map(item => item.widgetId);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          const currCol = columns.filter(item => item.widgetId === key)[0];
          if (!columnIds.includes(key)) continue;
          if (currCol.dataType === "Custom") continue;
          if (currCol.dataType === "Link") continue;
          if (currCol.dataType === "Image") continue;
          if (currCol.dataType === "Hyperlink") continue;
                // if (currCol.dataType === "Email") continue;
          if (currCol.dataType === "User") continue;
          if (currCol.dataType === "Date") continue;
          let filterText;
          let data = row[key] ? row[key].toString() : '';
          if (currCol.dataType === "Number" || currCol.dataType === "ProgressBar") {
            filterText = data ? data.toLowerCase().includes(text ? text.toLowerCase() : '') :
              '' && this.isColActive({ widgetId: key });
          } else {
            filterText = data ? data.toLowerCase().includes(text ? text.toLowerCase() : '') :
              '' && this.isColActive({ widgetId: key });
          }
          if (filterText) {
            hasText = true;
            break;
          }
        }
        return hasText;
      });
    }

    onUpdateFilter = (allColumns, isClear) => {
      let _rows = this.state.originalRows;
      if (allColumns.length === 0) {
        this.paginationCalc(this.state.originalRows);
        return;
      }
      let newRows = [];
      const activeFilters = allColumns.filter(item => item.filterable);
      for (let i = 0; i < activeFilters.length; i++) {
        if (i === 0) {
          _rows = this.state.originalRows;
        } else {
          _rows = newRows;
        }
        const currentFilter = activeFilters[i];
        if (currentFilter.opId === "between") {
          const min = parseInt(currentFilter.minValue, 10);
          const max = parseInt(currentFilter.maxValue, 10);
          if (isNaN(min) || isNaN(max)) continue;
          newRows = _rows.filter(item => {
            const val = parseInt(item[currentFilter.widgetId], 10);
            return min <= val && val <= max;
          });
        } else if (currentFilter.opId === "=") {
          let text = currentFilter.values;
          if (currentFilter.dataType === 'Number') {
            text = parseInt(text, 10);
          }
          newRows = _rows.filter(function(o) {
            return text.indexOf(o[currentFilter.widgetId]) !== -1;
          });
        } else if (currentFilter.opId === "contains") {
          let text = currentFilter.values;
          newRows = _filter(_rows, function(obj) {
            if (obj[currentFilter.widgetId]) {
              let oData = obj[currentFilter.widgetId].toLowerCase();
              return oData.includes(text.toLowerCase());
            }
          });
        } else if (currentFilter.opId === "!=") {
          let text = currentFilter.values;
          if (currentFilter.dataType === 'Number') {
            text = parseInt(text, 10);
          }
          newRows = _rows.filter(function(o) {
            return text.indexOf(o[currentFilter.widgetId]) === -1;
          });
        } else if (currentFilter.opId === "<") {
          let text = currentFilter.values;
          text = parseInt(text, 10);
          newRows = _filter(_rows, function(obj) {
            let newtext = parseInt(obj[currentFilter.widgetId], 10);
            return newtext < text;
          });
        } else if (currentFilter.opId === ">") {
          let text = currentFilter.values;
          text = parseInt(text, 10);
          newRows = _filter(_rows, function(obj) {
            let newtext = parseInt(obj[currentFilter.widgetId], 10);
            return newtext > text;
          });
        }
        this.setState({
          isFilter: true
        });
        this.paginationCalc(newRows);
      }
    }

    searchRows(searchText) {
      let finalRows = this.state.originalRows;
      if (searchText.target.value.length !== 0) {
        finalRows = this.applySearchRowsData(searchText.target.value, this.state.originalRows);
      }
        // this.setState({
        //   rows: finalRows,
        //   citrus: finalRows
        // })
      this.paginationCalc(finalRows);
    }

    searchColumnsAll(text) {
      var updatedList = this.state.allColumns;
      updatedList = updatedList.filter(function(item) {
        let name = item.displayName ? item.displayName : '';
        return name.toLowerCase().search(
          text.toLowerCase()) !== -1;
      });
      this.setState({ filteredColumns: updatedList });
    }

    findColumnId(id) {
      let data = _find(this.state.allColumns, function(o) { return o.id === id; });
      return !!data;
    }


    showTotal = (total) => {
      return `${total} ${this.props.paginationName}`;
    }

    onChangePageSize = (size) => {
      let { pagination, originalRows } = this.state;
      pagination['pageSize'] = size;
      pagination["currentPage"] = 1;
      this.setState({
        pagination
      });
      this.paginationCalc(originalRows);
    }

    paginationCalc = (rowData) => {
      let { pagination, rows } = this.state;
      let finalRows = rowData ? rowData : rows;
      pagination.totalCount = finalRows.length;
      let totalPage = Math.ceil(pagination.totalCount / pagination.pageSize);
      var citrus = finalRows.slice(pagination.skip, pagination.skip + pagination.pageSize);
      pagination["citrus"] = citrus;
      pagination["totalPage"] = totalPage;
      this.setState({
        pagination,
        citrus
      });
    }

    nextPage = () => {
      let { pagination } = this.state;
      if (pagination.currentPage < pagination.totalPage) {
        pagination['currentPage']++;
      }
      pagination['skip'] = pagination.pageSize * (pagination.currentPage - 1);
      this.setState({
        pagination
      });
      this.paginationCalc();
    }

    previousPage = () => {
      let { pagination } = this.state;
      if (pagination.currentPage > 1) {
        pagination['currentPage']--;
      }
      pagination['skip'] = pagination.pageSize * (pagination.currentPage - 1);
      this.setState({
        pagination
      });
      this.paginationCalc();
    }

    footer = () => (<div className={styles.paginationBtn}>
      <div className={styles.pageSizeList}>
        {/* <span>Show</span> */}
        {/* <CESelect
       value={this.state.pagination.pageSize}
       defaultValue={{ key: 'name' }}
       disabled={this.state.readOnly}
       onChange={this.onChangePageSize}
     >
       {this.state.pageSize.map(item => {
         return (
           <Option value={item.id} key={item.id}>
             {item.name}
           </Option>
         );
       })}
     </CESelect> */}
      </div>
      <span>Rows per page</span>
      <div>
        <GzSelect
          onChange={this.onChangePageSize}
          showSearch={false}
          className={styles.paginationdd}
          values={this.state.pageSize}
                //  bordered={true}
          value={this.state.pagination['pageSize']}
          defaultValue="10" />
      </div>
      <div className={styles.pageSizeInfo}>
        <GzIcon name="left" onClick={this.previousPage} />
        <p>Page {this.state.pagination.currentPage + ' of ' + this.state.pagination.totalPage}</p>
        <GzIcon name="right" onClick={this.nextPage} />
      </div>
    </div>);

    onHeader = (f, s, col) => {
      this.onChangeHeader(col);
    }


    render() {
      let columns = this.state.columns.map((col, index) => ({
        ...col,
        onHeaderCell: column => ({
          width: column.width,
          onResize: this.handleResize(index)
        }),
        title: (filters, sortOrder) => {
          return col['isSort'] ?
            <div
              onClick={() => this.onHeader(filters, sortOrder, col)}
              className={styles.sortingOrder}
            >
              <span>{col['displayName']}</span>
              {col['id'] !== this.state.sortId && <GzIcon name={"sort"} />}
              {col['id'] === this.state.sortId && <GzIcon name={"sort"} />}
            </div> :
            <div className={styles.sortingOrder}><span>{col['displayName']}</span></div>;
        }
      }));

      columns = _filter(columns, function(col) { return col.dataType !== 'Image'; });

      if (!this.props.isResize) {
        for (let index = 0; index < columns.length; index++) {
          Reflect.deleteProperty(columns[index], 'onHeaderCell');
        }
      }
      let config = {
        footer: false,
        pagination: {
          position: ['none'],
          pageSize: this.state.pagination['pageSize'],
          currentPage: this.state.pagination["currentPage"]
        }
      };

      let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          this.props.isSelection.rowSelectionFunction(selectedRowKeys);
        },
        getCheckboxProps: record => ({
          // Column configuration not to be checked
          disabled: this.props.isSelection.disableCheckBox.value.includes(record[this.props.isSelection.disableCheckBox.key]),
          // name: record.name,
          hideDefaultSelections: false
        }),
        selectedRowKeys: this.props.isSelection.selectedRowKeys
      };
      return (
        <div className={`${styles.dataTableHome} ${this.props.className ? this.props.className : ''}`}>
          <Table
            onChange={this.onChangeHeader}
            columns={columns}
            onRow={(record, index) => ({
              onClick: () => { this.props.onRow(record, index); },
              onMouseEnter: event => { this.props.onRowEnter(record, index); },
              onMouseLeave: event => { this.props.onRowHoverLeave(record, index); }
            })}
            rowKey={this.props.rowKey}
            rowSelection={this.props.isSelection['select'] ? rowSelection : null}
            {...config}
            loading={this.props.tableLoading}
            pagination={false}
            footer={(this.props.pagination && this.state.pagination['citrus'].length) ? this.footer : false}
            dataSource={this.state.pagination['citrus']}
            components={this.components}
            scroll={{ y: "calc(100vh - 315px)" }}
          />
        </div>
      );
    }
}
