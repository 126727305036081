import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { GzIcon } from '../icon';

const { SubMenu } = Menu;

export class GzMenu extends PureComponent {
  // submenu keys of first level
  static propTypes = {
    mode: PropTypes.string,
    // openKeys: PropTypes.array,
    menus: PropTypes.array,
    theme: PropTypes.string,
    inlineCollapsed: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    defaultSelectedKeys: PropTypes.array,
    menuItemClassName: PropTypes.string,
    selectedKeys: PropTypes.array,
    popupClassName: PropTypes.string
  }
  static defaultProps = {
    mode: 'horizontal',
    menus: [],
    theme: 'light',
    inlineCollapsed: false,
    className: '',
    onClick: () => {},
    defaultSelectedKeys: [],
    menuItemClassName: '',
    selectedKeys: [],
    popupClassName: ''
  }

  constructor(props) {
    super(props);
    this.state = {
      openKeys: []
    };
  }
  
  rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : []
      });
    }
  };

  render() {
    const {
      className,
      mode,
      menus,
      theme,
      inlineCollapsed,
      onClick,
      defaultSelectedKeys,
      menuItemClassName,
      selectedKeys,
      popupClassName
    } = this.props;
    
    const {
      openKeys = []
    } = { ...this.state };

    const subMenu = menus.map(mainMenu => ((mainMenu.subMenu) ?
      (
        <SubMenu
          key={mainMenu.key}
          popupClassName={popupClassName}
          title={(
            <span>
              {(mainMenu.menuIcon && mainMenu.menuIcon !== '') && <GzIcon name={mainMenu.menuIcon} />}
              <span>{mainMenu.menuName}</span>
            </span>
          )}
          disabled={mainMenu.menuDisabled}
        >
          {(mainMenu.subMenu.length > 0) && mainMenu.subMenu.map(submenu => (
            <Menu.Item className={menuItemClassName} key={submenu.key} popupClassName={popupClassName}>
              {submenu.name}
            </Menu.Item>
          ))}
        </SubMenu>
      ) : (
        <Menu.Item className={menuItemClassName} key={mainMenu.key} disabled={mainMenu.menuDisabled}>
          <span >
            {(mainMenu.menuIcon && mainMenu.menuIcon !== '') && <GzIcon name={mainMenu.menuIcon} />}
            <span>{mainMenu.menuName}</span>
          </span>
        </Menu.Item>
      )));
      
    const props = () => {
      if (mode === 'inline') {
        return {
          inlineCollapsed: inlineCollapsed
        };
      }
      return {};
    };
    return (
      <Menu
        mode={mode}
        openKeys={openKeys}
        onOpenChange={this.onOpenChange}
        theme={theme}
        className={className}
        onClick={onClick}
        selectedKeys={selectedKeys}
        defaultSelectedKeys={defaultSelectedKeys}
        popupClassName={popupClassName}
        {...props()}
      >
        {subMenu}
      </Menu>
    );
  }
}
