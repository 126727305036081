import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { GzIcon } from '../icon';

class GzModal extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    visible: PropTypes.bool,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.array
    ]),
    wrapClassName: PropTypes.string,
    isFooter: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    className: PropTypes.string,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    footer: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.array,
      PropTypes.string
    ]),
    closable: PropTypes.bool,
    bodyStyle: PropTypes.object,
    iconName: PropTypes.string,
    iconClassname: PropTypes.string,
    width: PropTypes.number
  }

  static defaultProps = {
    title: '',
    visible: false,
    onOk: () => { },
    onCancel: () => { },
    children: null,
    confirmLoading: false,
    footer: null,
    className: '',
    isFooter: false,
    wrapClassName: '',
    okText: 'Ok',
    cancelText: 'Cancel',
    closable: true,
    bodyStyle: {},
    iconName: 'x',
    iconClassname: '',
    width: 520
  }

  render() {
    const {
      title,
      visible,
      onOk,
      onCancel,
      children,
      confirmLoading,
      footer,
      className,
      wrapClassName,
      okText,
      cancelText,
      isFooter,
      closable,
      bodyStyle,
      iconName,
      iconClassname,
      width
    } = this.props;
    let customFooter;
    if (isFooter) {
      customFooter = footer;
    }
    return (
      <Modal
        className={className}
        visible={visible}
        title={title}
        onOk={onOk}
        closable={closable}
        onCancel={onCancel}
        okText={okText}
        cancelText={cancelText}
        centered
        confirmLoading={confirmLoading}
        wrapClassName={wrapClassName}
        closeIcon={<GzIcon name={iconName} className={iconClassname}/>}
        footer={customFooter}
        bodyStyle={bodyStyle}
        width={width}
      >
        {children}
      </Modal>
    );
  }
}

export {
  GzModal
};
