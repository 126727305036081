/* eslint-disable camelcase */
import { notification } from 'antd';
import { getErrorText as _getErrorText } from '../helpers/error.helper';
import { Logger } from './logger';

/* Toast object with methods */
export const toast = {
  success: (message, option) => success(message, option),
  error: (err, option) => error(err, option),
  warn: (message, option) => warn(message, option),
  info: (message, option) => info(message, option)
};

/* Success toast message */
export const success = (message, option) => {
  notification.success({
    message: 'Success',
    description: message
  });
};

/* Error toast message */
export const error = (err, option) => {
  let message = '';
  if (typeof err === 'string') {
    message = err;
  } else if (err.hasOwnProperty('message') && err.hasOwnProperty('error_code')) {
    message = err.message;
  } else {
    if (!err?.error_code) {
      err.error_code = 'APP_ERROR_UNEXPECTED';
    }
    message = getErrorText(err);
  }
  
  Logger.error(err);
  if (message !== 'Token Expired' || message !== 'Invalid Token') {
    notification.error({
      message: 'Error',
      description: message
    });
  }
};

/* Warning toast message */
export const warn = (message, option) => {
  notification.warning({
    message: 'Warning',
    description: message
  });
};

/* Info toast message */
export const info = (message, option) => {
  notification.info({
    message: 'Info',
    description: message
  });
};

/* Get error text */
const getErrorText = (err) => {
  if (typeof err === 'string') {
    return err;
  }
  return _getErrorText(err.error_code, err.args);
};
