import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './status.module.scss';

export default class TBLStatus extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object
    ]),
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    isEdit: PropTypes.bool
  }
  static defaultProps = {
    value: '',
    isEdit: true
  }


  render() {
    let { value } = this.props;
    return (
      <div className={styles.statusDesign}>
        <div className={`${styles.status} ${styles[value]}`}>  {value}</div>
      </div>
    );
  }
}

export { TBLStatus };
