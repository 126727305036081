import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Subject } from 'rxjs';
import './loader.scss';

/* Expose the subject as observable */
const onLoaderChange$ = new Subject();
const onLoaderChange = () => onLoaderChange$.asObservable();

// Listener object to handle unsubscription
const loaderListener = { subscription: undefined };

/* Exposable function to change the loader */
export const setLoader = (isLoading, timer = 1000) => { onLoaderChange$.next({ loading: isLoading, timer: timer }); };

export function Loader(props) {
    // Props from parent component
  const { manual } = props;

    // States for the component
  const [isLoading, setIsLoading] = useState(!manual);

    // Listener the loader change events
  useEffect(() => {
    loaderListener.subscription = onLoaderChange().subscribe(({ loading, timer }) => {
            // Start the loader
      if (loading) {
        setIsLoading(loading);
      } else {
                // Small timeout while stop the loader, to make view better
        setTimeout(() => setIsLoading(loading), timer);
      }
    });

        // Unsubscribe all the listeners
    return () => {
      loaderListener.subscription.unsubscribe();
    };
  }, []);

    /**
     * Render the JSX elements
     */
  return (
    <div className={`cob-app-container ${!isLoading ? 'loaded' : ''}`}>
      <div className="cob-app-container-spinner">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

Loader.propTypes = {
  manual: propTypes.bool
};
