import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import styles from './imagelist.module.scss';
import { GzIcon } from 'library/shared/widgets';

export default class TBLImagelist extends Component {
  static propTypes = {
    items: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
      PropTypes.array
    ]),
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    isEdit: PropTypes.bool,
    row: PropTypes.object,
    value: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string
    ]),
    onDownload: PropTypes.func,
    onGroupManageUsers: PropTypes.func
  }

  static defaultProps = {
    items: [],
    isEdit: true,
    row: {},
    value: null,
    onDownload: () => { },
    onGroupManageUsers: () => { }
  }

  productName = (name) => {
    if (name === 'POWER_BI') {
      return 'Power BI';
    } else if (name === 'TABLEAU') {
      return 'Tableau';
    } else if (name === 'TABLEAU PREP') {
      return 'Tableau';
    } else if (name === 'QLIK') {
      return 'Qlik';
    }
  }

  render() {
    const { value, row } = this.props;
    return (
      <div className={styles.imagelistDesign}>

        {/* license table */}
        { row['licenseKey'] &&
          <Fragment>
            <div className={styles.licenseLogoFlexContainer}>
              {
                value.Product.map(item => (
                  item !== 'TABLEAU_PREP' &&
                    <Tooltip title={this.productName(item)} key={item.toUpperCase()}>
                      <GzIcon name={`ic_${item.toLowerCase()}`} className={styles.licenseProductLogos}/>
                    </Tooltip>
                ))
              }
              <Tooltip title={value.Edition} key={value.Edition}>
                <GzIcon
                  name={value.Edition === 'Desktop' ? 'monitor' : value.Edition.toLowerCase()}
                  className={styles.licenseProductLogos}
                />
              </Tooltip>
            </div>
            
          </Fragment>
        }
        { row['Subject'] &&
          <Fragment>
            <div className={styles.licenseLogoFlexContainer}>
              {/* eslint-disable-next-line no-nested-ternary */}
              <Tooltip title={row.Product === 'General' ?
                row.Product : row.Product.includes('Power BI') ? 'Power BI' : row.Product.split(' ')[0]}>
                <GzIcon
                  // eslint-disable-next-line no-nested-ternary
                  name={`ic_${row.Product === 'general' ?
                    row.Product.toLowerCase() : row.Product.includes('Power BI') ? 'power_bi' : row.Product.split(' ')[0].toLowerCase()}`}
                  className={styles.licenseProductLogos}
                />
              </Tooltip>
            </div>
          </Fragment>
        }
        { (row['AccountOwnerEmail'] && value) &&
          <div className={styles.alignIcons}>
            { (value.length > 0) ?
              <Fragment>
                { value.map((item, i) => (
                  <Tooltip
                    key={i}
                    // eslint-disable-next-line no-nested-ternary
                    title={`${this.productName(item.Product)} - ${item.Edition}`}
                  >
                    <div className={`${styles.licenseLogoFlexContainer} ${styles[item.Status]}`} key={i}>
                      <GzIcon
                        name={`ic_${item.Product === 'TABLEAU_PREP' ? 'tableau' : item.Product.toLowerCase()}`}
                        className={styles.licenseProductLogos}
                      />
                      <GzIcon
                        name={item.Edition === 'Desktop' ? 'monitor' : item.Edition.toLowerCase()}
                        className={styles.licenseProductLogos}
                      />
                    </div>
                  </Tooltip>
                ))
                }
              </Fragment> : <span>-</span>
            }
          </div>
        }
      </div>
    );
  }
}

export { TBLImagelist };
