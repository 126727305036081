import React, { memo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SkeletonLoader } from '../index';
import { StoreContext } from 'Store.context';
import noPreviewImage from './noPreviewImage.svg';

function ImageRetriever(props) {

    // Props from the parent component
    const { imagePath, styles, onImageRetrieved, className, fallbackContent } = props;

    // Component hooks instance declarations
    const { storeState: { userInfo: { accessToken } } } = useContext(StoreContext);

    // States for the component
    const [imageURL, setImageURL] = useState();
    const [isBrokenImage, setIsBrokenImage] = useState(false);

    /* Handler for retrieve image from cloud when image path changed */
    useEffect(() => {

        retrieveImage();

        // eslint-disable-next-line
    }, [imagePath, accessToken]);

    /* Handler for retrieve image from cloud */
    const retrieveImage = async () => {

        try {
            const imageBlob = await (
                await fetch(imagePath, { headers: { 'Authorization': `Bearer ${accessToken}` } })
            )?.blob();

            const objectURL = URL.createObjectURL(imageBlob);
            setImageURL(objectURL);

        } catch (error) {
            console.error(error);
            setImageURL('placeholder');
        }

        if (onImageRetrieved) {
            onImageRetrieved();
        }
    };

    /* Render the JSX elements */
    return imageURL ?
        (isBrokenImage && fallbackContent) ? (<span className='fallBack'>{fallbackContent}</span>) : (
            <img
                className={className || ''}
                style={styles}
                src={imageURL}
                alt='Thumbnail'
                onError={(event) => { event.target.src = noPreviewImage; setIsBrokenImage(true); }} />
        ) : <SkeletonLoader />;
}

export default memo(ImageRetriever);

ImageRetriever.propTypes = {
    imagePath: PropTypes.string,
    styles: PropTypes.object,
    onImageRetrieved: PropTypes.func,
    className: PropTypes.string,
    fallbackContent: PropTypes.any
};
