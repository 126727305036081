import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './imagewithtext.module.scss';
import { GzIcon } from 'library/shared/widgets';

export default class TBLImagewithtext extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object
    ]),
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    isEdit: PropTypes.bool,
    row: PropTypes.object,
    onDatasourceClick: PropTypes.func
  }
  static defaultProps = {
    value: '',
    isEdit: true,
    row: null,
    onDatasourceClick: () => {}
  }

  returnLogo = (value) => {
    if (value === 'Admin') {
      return 'eye';
    } else if (value === 'Account Owner') {
      return 'eye';
    } else if (value === 'User') {
      return 'user';
    } else {
      return 'edit3';
    }
  }


  render() {
    let { value, row, onDatasourceClick } = this.props;
    return (
      <div className={styles.imagewithtextDesign}>
        {/* users table */}
        { row['UserState'] &&
          <Fragment>
            <GzIcon name={this.returnLogo(value)} className={styles.userIcon}/>
            <span className={styles.text}>{value}</span>
          </Fragment>
        }
      </div>
    );
  }
}

export { TBLImagewithtext };
