import { HttpService } from 'library/common/services';

const version = 1;

const CobHeaderService = {

  isEmailVerified: () => HttpService.get(`/api/${version}/user/isEmailVerified`),

  getUserProfile: () => HttpService.get(`/api/${version}/user/getUserProfile`),

  sendNotification: userDetails => HttpService.post(`/api/${version}/user/sendNotification`,
    { data: userDetails }
  )
};

export default CobHeaderService;
