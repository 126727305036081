import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AutoComplete, Input } from 'antd';
import { GzIcon } from '../icon';

const { Option } = AutoComplete;

class GzAutocomplete extends PureComponent {
  static propTypes = {
    allowClear: PropTypes.bool,
    autoFocus: PropTypes.bool,
    backfill: PropTypes.bool,
    options: PropTypes.array,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.node,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onSearch: PropTypes.func,
    onSelect: PropTypes.func,
    icon: PropTypes.bool
  }

  static defaultProps = {
    allowClear: false,
    autoFocus: false,
    backfill: false,
    options: [],
    disabled: false,
    placeholder: 'Filter',
    value: null,
    onBlur: () => { },
    onFocus: () => { },
    onSearch: () => { },
    onSelect: () => { },
    icon: false
  }

  /*  filterOption = (inputValue, option) => (
    option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
  ); */

  render() {
    const {
      allowClear,
      autoFocus,
      backfill,
      options,
      disabled,
      placeholder,
      value,
      onBlur,
      onFocus,
      onSearch,
      onSelect,
      icon
    } = this.props;
    const listOptions = options.map((value) => <Option key={value}>{value}</Option>);
    return (
      <AutoComplete
        allowClear={allowClear}
        autoFocus={autoFocus}
        backfill={backfill}
        disabled={disabled}
        value={value}
        options={listOptions}
        filterOption={(inputValue, option) =>
          option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
        placeholder={placeholder}
        onBlur={onBlur}
        style={{ width: 200 }}
        onFocus={onFocus}
        onSelect={onSelect}
        onSearch={onSearch}
      >
        {icon && <Input suffix={<GzIcon name="ico_small_tick" className="certain-category-icon" />} />}
      </AutoComplete>
    );
  }
}

export default GzAutocomplete;
