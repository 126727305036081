import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';

class GzList extends PureComponent {
  static propTypes = {
    bodered: PropTypes.bool,
    dataSource: PropTypes.array,
    header: PropTypes.node,
    actions: PropTypes.array,
    pagination: PropTypes.bool,
    footer: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element
    ]),
    extra: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element
    ]),
    itemLayout: PropTypes.string,
    loading: PropTypes.bool,
    loadMore: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element
    ])
  }

  static defaultProps = {
    bodered: false,
    dataSource: [],
    actions: [],
    pagination: false,
    footer: null,
    extra: null,
    itemLayout: 'horizontal',
    header: null,
    loading: false,
    loadMore: null
  }

  render() {
    const {
      bodered,
      dataSource,
      actions,
      pagination,
      footer,
      extra,
      itemLayout,
      header,
      loading,
      loadMore
    } = this.props;
    const renderItemFunc = (item) => {
      let children;
      if (typeof item === 'object') {
        children = (
          <List.Item.Meta
            avatar={item.avatar}
            title={item.title}
            description={item.description}
          />
        );
      } else {
        children = item;
      }
      return (
        <List.Item actions={actions}>
          {children}
        </List.Item>
      );
    };
    return (
      <List
        bodered={bodered.toString()}
        header={header}
        itemLayout={itemLayout}
        loadMore={loadMore}
        dataSource={dataSource}
        renderItem={item => (renderItemFunc(item))}
        footer={footer}
        loading={loading}
        extra={extra}
        pagination={pagination}
      />
    );
  }
}

export default GzList;
