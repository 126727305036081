export const STORE_ACTIONS = {
  ACCOUNT_INFO: 'account-info',
  USER_INFO: 'user-info',
  APP_GO_BACK_URL: 'app-go-back-url',
  NOTIFICATION_INFO: 'notification-info',
  CART_INFO: 'cart-info'
};

export const STORE_KEYS = {
  ACCOUNT_INFO: 'accountInfo',
  USER_INFO: 'userInfo',
  APP_GO_BACK_URL: 'appGoBackURL',
  NOTIFICATION_INFO: 'notificationInfo',
  CART_INFO: 'cart-info'
};
