import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';

export class GzAlert extends PureComponent {
  static propTypes = {
    message: PropTypes.oneOfType([PropTypes.string,
      PropTypes.node]),
    type: PropTypes.oneOfType([PropTypes.func,
      PropTypes.string]),
    closable: PropTypes.oneOfType([PropTypes.func,
      PropTypes.bool]),
    onClose: PropTypes.func,
    description: PropTypes.oneOfType([PropTypes.string,
      PropTypes.node]),
    showIcon: PropTypes.oneOfType([PropTypes.func,
      PropTypes.bool]),
    banner: PropTypes.bool,
    closeText: PropTypes.string
  }

  static defaultProps = {
    message: '',
    type: '',
    closable: false,
    onClose: () => { },
    description: '',
    showIcon: false,
    banner: false,
    closeText: ''
  }

  render() {
    const {
      message,
      type,
      closable,
      onClose,
      description,
      showIcon,
      banner,
      closeText
    } = this.props;
    return (
      <Alert
        message={message}
        type={type}
        closable={closable}
        onClose={onClose}
        description={description}
        showIcon={showIcon}
        banner={banner}
        closeText={closeText}
      />
    );
  }
}

