import React, { PureComponent } from 'react';
import { Switch } from 'antd';
import PropTypes from 'prop-types';

class GzSwitch extends PureComponent {
  static propTypes = {
    autoFocus: PropTypes.bool,
    checked: PropTypes.bool,
    checkedChildren: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    size: PropTypes.string,
    unCheckedChildren: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    className: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ])
  }

  static defaultProps = {
    autoFocus: false,
    checked: false,
    checkedChildren: '',
    disabled: false,
    loading: false,
    size: 'default',
    unCheckedChildren: '',
    onChange: () => { },
    onClick: () => { },
    className: '',
    value: false
  }

  render() {
    const {
      autoFocus,
      checked,
      checkedChildren,
      disabled,
      loading,
      size,
      unCheckedChildren,
      onChange,
      onClick,
      value,
      className
    } = this.props;
    return (
      <Switch
        autoFocus={autoFocus}
        onChange={onChange}
        checked={checked}
        checkedChildren={checkedChildren}
        disabled={disabled}
        loading={loading}
        size={size}
        value={value}
        unCheckedChildren={unCheckedChildren}
        onClick={onClick}
        className={className}
      />
    );
  }
}

export default GzSwitch;
