import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Mentions } from 'antd';


class GzMentions extends PureComponent {
  static propTypes = {
    defaultValue: PropTypes.string,
    onSelect: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.array,
    placement: PropTypes.string
  }

  static defaultProps = {
    defaultValue: '',
    onSelect: () => { },
    onChange: () => { },
    value: [],
    placement: 'bottom'
  }

  render() {
    const { Option } = Mentions;
    const {
      defaultValue,
      onSelect,
      onChange,
      value,
      placement
    } = this.props;

    return (
      <Mentions
        defaultValue={defaultValue}
        onSelect={onSelect}
        onChange={onChange}
        placement={placement}
      >
        {
          value.map(val => <Option key={val} value={val}>{val}</Option>)
        }
      </Mentions>
    );
  }
}

export default GzMentions;
