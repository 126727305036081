import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';

export class GzDivider extends PureComponent {
  static propTypes = {
    dashed: PropTypes.bool,
    orientation: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string
  }

  static defaultProps = {
    dashed: false,
    orientation: 'center',
    type: 'horizontal',
    text: '',
    className: ''
  }

  render() {
    const {
      dashed,
      orientation,
      type,
      text,
      className
    } = this.props;

    return (
      <React.Fragment>
        <Divider
          dashed={dashed}
          orientation={orientation}
          type={type}
          className={className}
        >
          {text}

        </Divider>
      </React.Fragment>
    );
  }
}
