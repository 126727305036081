import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { COMPONENT_NAMES } from '../constants';
import { Icon } from '../../icon/Icon';

export function GuidanzTablePagination(props) {
	// Props from the parent component
	const { paginationData, onPaginationChange, children } = props;
	// Local state maintenance
	const alternatives = [
		{ key: 10, value: 10, name: '10 rows' },
		{ key: 25, value: 25, name: '25 rows' },
		{ key: 50, value: 50, name: '50 rows' },
		{ key: 100, value: 100, name: '100 rows' }
	];

	/* Handler to perform pagination prev functionality */
	const onClickPrev = () => {
		if (paginationData.currentPage !== 1) {
			paginationData.currentPage -= 1;
		}

		onPaginationChange({ ...paginationData });
	};

	/* Handler to perform pagination next functionality */
	const onClickNext = () => {
		if (paginationData.currentPage !== paginationData.lastPage) {
			paginationData.currentPage += 1;
		}

		onPaginationChange({ ...paginationData });
	};
	/* Render the JSX elements */
	return (
		<div className={`table-pagination-wrapper ${children ? 'justify-between' : 'justify-end'}`}>
			{children}
			<div className='pagination-wrapper-right'>
				<div className='page-alternatives-info'>
					<span className='show-text'>Show</span>

					<Select
						value={paginationData.itemsPerPage}
						// eslint-disable-next-line max-len
						onChange={value => onPaginationChange({ ...paginationData, itemsPerPage: value })}
					>
						{alternatives.map(e => (
							<Select.Option key={e.key} value={e.value}>
								{e.value}
							</Select.Option>
						))}
					</Select>

					<span className='show-text'>per page</span>
				</div>

				<div className='page-size-info'>
					<Icon name='left' disabled={paginationData.currentPage === 1} onClick={onClickPrev} />
					<span className='index'>
						Page {paginationData.currentPage} of {paginationData.lastPage}
					</span>
					<Icon
						name='right'
						disabled={paginationData.currentPage === paginationData.lastPage}
						onClick={onClickNext}
					/>
				</div>
			</div>
		</div>
	);
}

GuidanzTablePagination.propTypes = {
	paginationData: PropTypes.objectOf(PropTypes.number),
	onPaginationChange: PropTypes.func,
	children: PropTypes.element
};

GuidanzTablePagination.defaultProps = {
	paginationData: {},
	onPaginationChange: () => {}
};

GuidanzTablePagination.displayName = COMPONENT_NAMES.GuidanzTablePagination;
