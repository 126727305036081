import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Radio, Menu, Dropdown } from 'antd';
import { GzIcon } from '../icon';

class GzButton extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.string,
    icon: PropTypes.string,
    isLoading: PropTypes.bool,
    shape: PropTypes.string,
    isDisabled: PropTypes.bool,
    block: PropTypes.bool,
    ghost: PropTypes.bool,
    htmlType: PropTypes.string,
    className: PropTypes.string,
    href: PropTypes.string
  }

  static defaultProps = {
    type: 'primary',
    name: '',
    size: 'default',
    htmlType: 'button',
    icon: '',
    onClick: () => { },
    isLoading: false,
    shape: 'square',
    isDisabled: false,
    block: false,
    ghost: false,
    className: ''
  }

  icon = icon => <GzIcon name={icon} btnIcon={!!icon} />;

  render() {
    const {
      type,
      name,
      size,
      htmlType,
      icon,
      onClick,
      isLoading,
      shape,
      isDisabled,
      block,
      ghost,
      href,
      className
    } = this.props;
    const style = icon ? {
      display: 'grid',
      gridAutoFlow: 'column',
      alignItems: 'center'
    } : {};

    return (
      <Button
        className={className}
        style={ style }
        type={type}
        size={size}
        htmlType={htmlType}
        href={href}
        icon={icon ? this.icon(icon) : ''}
        loading={isLoading}
        onClick={onClick}
        shape={shape}
        disabled={isDisabled}
        block={block}
        ghost={ghost}
      >
        {name}
      </Button>
    );
  }
}


class GzRadioButton extends PureComponent {
  static propTypes = {
    list: PropTypes.array,
    values: PropTypes.array,
    value: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string
  }

  static defaultProps = {
    list: [],
    values: [],
    value: '',
    type: 'default',
    onChange: () => { },
    className: ''
  }

  render() {
    const {
      list,
      values,
      value,
      type,
      onChange,
      className
    } = this.props;
    return (
      <Radio.Group className={`Gz_${type} ${className}`} value={value} onChange={onChange}>
        {list.map((item, i) => <Radio.Button key={item} value={values[i]}>{item}</Radio.Button>)}
      </Radio.Group>
    );
  }
}

class GzGroupButton extends PureComponent {
  static propTypes = {
    list: PropTypes.array,
    values: PropTypes.array,
    onClick: PropTypes.func,
    isLoading: PropTypes.bool,
    type: PropTypes.string,
    icon: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string
  }

  static defaultProps = {
    list: [],
    values: [],
    isLoading: false,
    onClick: () => { },
    size: 'default',
    type: 'primary',
    icon: '',
    className: ''
  }

  icon = icon => <GzIcon name={icon} />;

  render() {
    const {
      list,
      values,
      isLoading,
      onClick,
      size,
      type,
      icon,
      className
    } = this.props;
    return (
      <Button.Group size={size}>
        {list.map((item, i) => (<Button className={`Gz_${type} ${className}`} key={item}
          type={type} value={values[i]} icon={this.icon(icon)}
          loading={isLoading} onClick={onClick}>{item}</Button>))}
      </Button.Group>
    );
  }
}

class GzDropDownButton extends PureComponent {
  static propTypes = {
    list: PropTypes.array,
    onClick: PropTypes.func,
    onSelect: PropTypes.func,
    type: PropTypes.string,
    icon: PropTypes.string,
    size: PropTypes.string,
    btnName: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.string
    ]),
    menuClassName: PropTypes.string,
    className: PropTypes.string
  }

  static defaultProps = {
    list: [],
    onClick: () => {},
    onSelect: () => {},
    size: 'default',
    type: 'default',
    icon: '',
    btnName: '',
    className: '',
    menuClassName: ''
  }

  render() {
    const {
      list,
      onClick,
      size,
      type,
      icon,
      btnName,
      className,
      onSelect,
      menuClassName
    } = this.props;

    const MENU = (
      <Menu onClick={onClick} onSelect={onSelect} className={menuClassName}>
        { list.map(item => (
          <Menu.Item
            key={item.key}
            icon={item.menuIcon && item.menuIcon !== '' ? <GzIcon name={item.menuIcon} /> : null}
          >
            {item.menuName}
          </Menu.Item>
        ))
        }
      </Menu>
    );

    const STYLE = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };
    
    return (
      <Dropdown
        overlay={MENU}
        className={className}
        size={size}
        trigger={['click']}
      >
        <Button type={type} style={STYLE}>
          {btnName}&nbsp;<GzIcon name={icon} />
        </Button>
      </Dropdown>
    );
  }
}

export {
  GzButton,
  GzRadioButton,
  GzGroupButton,
  GzDropDownButton
};
