import PropTypes from 'prop-types';
import { COMPONENT_NAMES } from '../constants';

export function GuidanzTableRow(props) {
    // Props from the parent component
    const { children } = props;

    /* Render the JSX elements */
    return children;
}

GuidanzTableRow.propTypes = {
    children: PropTypes.node
};

GuidanzTableRow.displayName = COMPONENT_NAMES.GuidanzTableRow;
