import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import { GzIcon } from '../icon';

class GzAvatar extends PureComponent {
  static propTypes = {
    icon: PropTypes.string,
    shape: PropTypes.string,
    size: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    src: PropTypes.string,
    alt: PropTypes.string,
    onError: PropTypes.func
  }

  static defaultProps = {
    icon: '',
    shape: 'circle',
    size: 'large',
    src: '',
    alt: '',
    onError: () => { }
  }

  icon = icon => <GzIcon name={icon} />;

  render() {
    const {
      icon,
      shape,
      size,
      src,
      alt,
      onError
    } = this.props;
    return (
      <Avatar
        size={size}
        shape={shape}
        icon={this.icon(icon)}
        src={src}
        alt={alt}
        onError={onError}
      />
    );
  }
}

export default GzAvatar;
