import React, { PureComponent, Component } from 'react';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';

class GzCheckbox extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string,
    options: PropTypes.string,
    title: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string
  }

  static defaultProps = {
    disabled: false,
    name: '',
    options: '',
    title: '',
    onChange: () => { },
    className: ''
  }

  render() {
    const {
      disabled,
      name,
      options,
      title,
      onChange,
      className
    } = this.props;

    return (
      <Checkbox
        disabled={disabled}
        name={name}
        options={options}
        onChange={onChange}
        className={className}
      >
        {title}
      </Checkbox>
    );
  }
}

class GzCheckboxGroup extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    onChange: PropTypes.func
  }

  static defaultProps = {
    disabled: false,
    name: '',
    value: '',
    options: '',
    title: '',
    onChange: () => { },
    className: ''
  }

  render() {
    const {
      disabled,
      name,
      value,
      options,
      title,
      onChange,
      className
    } = this.props;

    return (
      <Checkbox
        disabled={disabled}
        name={name}
        value={value}
        options={options}
        onChange={onChange}
        className={className}
      >
        {title}
      </Checkbox>
    );
  }
}

export {
  GzCheckbox,
  GzCheckboxGroup
};
