export const COMPONENT_NAMES = {
    GuidanzTable: 'GuidanzTable',
    GuidanzTableHeader: 'GuidanzTableHeader',
    GuidanzTableTitle: 'GuidanzTableTitle',
    GuidanzTableHeaderOptions: 'GuidanzTableHeaderOptions',
    GuidanzTableHead: 'GuidanzTableHead',
    GuidanzTableRow: 'GuidanzTableRow',
    GuidanzCustomColumn: 'GuidanzCustomColumn',
    GuidanzTablePagination: 'GuidanzTablePagination',
    GuidanzTableBatchActions: 'GuidanzTableBatchActions'
};
