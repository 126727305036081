import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Result } from 'antd';

class GzResult extends PureComponent {
   static propTypes = {
     title: PropTypes.node,
     subTitle: PropTypes.node,
     status: PropTypes.string,
     icon: PropTypes.oneOfType([
       PropTypes.string,
       PropTypes.element
     ]),
     extra: PropTypes.element
   }

   static defaultProps = {
     title: null,
     subTitle: null,
     status: 'info',
     icon: null,
     extra: null
   }

   render() {
     const {
       title,
       subTitle,
       status,
       icon,
       extra
     } = this.props;
     return (
       <Result
         icon={icon}
         title={title}
         extra={extra}
         status={status}
         subTitle={subTitle}
       />
     );
   }
}

export default GzResult;
