import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'util';
import styles from './number.module.scss';
export default class TBLNumber extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object
    ]),
    color: PropTypes.string,
    bold: PropTypes.bool
  }
  
  static defaultProps = {
    value: '',
    color: '#0000',
    bold: false
  }
  
  render() {
    let { value, bold, color } = this.props;
    let data = value || "";
    let options = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 10
    };
    let showNum = '';
    this.locale = "en-US";
    if(isNumber(Number(data))) {
      showNum = Intl.NumberFormat(this.locale, options).format(data);
    }
    const css = {
      color: color,
      fontWeight: bold ? 600 : 500
    };
    
    return (
      <div style={css} className={styles.numberDesign}> {showNum} </div>
    );
  }
}

export { TBLNumber };
