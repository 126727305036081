import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './string.module.scss';
import { Tooltip } from 'antd';
import { GzIcon } from 'library/shared/widgets';

export default class TBLString extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object
    ]),
    width: PropTypes.number,
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    isEdit: PropTypes.bool,
    bold: PropTypes.bool,
    color: PropTypes.string,
    copy: PropTypes.bool
  }
  static defaultProps = {
    value: '',
    isEdit: true,
    width: 200,
    bold: false,
    color: '#00000',
    copy: false
  }
  valueRef = React.createRef();

  render() {
    let { value, width, bold, color, copy } = this.props;
    const css = {
      width,
      color,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    };

    const showTooltip = (value) => {
      if (this.valueRef.current) {
        return this.valueRef.current.offsetWidth < this.valueRef.current.scrollWidth ? value : '';
      }
    };

    return (
      <div className={styles.tableStringField}>
        <Tooltip title={showTooltip(value)}>
          <div
            style={{ ...css }}
            className={bold ? styles.stringDesign : ''}
            onMouseOver={this.mouseOver}
            ref={this.valueRef}
          >
            {value || '-'}
          </div>
        </Tooltip>
        <span className={copy ? styles.showCopy : styles.hideCopy}>
          <CopyToClipboard text={value}>
            <GzIcon name="copy" />
          </CopyToClipboard>
        </span>
      </div>
    );
  }
}

export { TBLString };
