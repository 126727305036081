import React from 'react';
import { Router } from './router.js';
import './index.scss';

function App() {
  return (
    <div className="app-container" id="cob-app-container">
      <Router />
    </div>
  );
}

export default App;
