import { createContext } from 'react';
import { STORAGE } from 'library/common/constants';
import { STORE_ACTIONS } from 'library/common/constants';

// Initial state for store

export const initialStoreState = {
  accountInfo: {},
  userInfo: {},
  appGoBackURL: '/home',
  notificationInfo: {},
  cartInfo: ''
};

/* Reducer for update state based on action */
export const storeReducer = (state, action) => {
  switch (action.type) {
  case STORE_ACTIONS.USER_INFO:
    localStorage.setItem(STORAGE.USER_INFO, JSON.stringify({ ...state.userInfo, ...action.userInfo }));
    return {
      ...state,
      userInfo: { ...state.userInfo, ...action.userInfo }
    };
  case STORE_ACTIONS.APP_GO_BACK_URL:
    return {
      ...state,
      appGoBackURL: action.appGoBackURL
    };
  case STORE_ACTIONS.CART_INFO:
    return {
      ...state,
      cartInfo: action.cartInfo
    };
  default:
    return state;
  }
};

// Context to hold application level data's
export const StoreContext = createContext();
