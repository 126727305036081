export const getComponentRelativePath = (currentRouterURL) => {
  if (currentRouterURL) {
    currentRouterURL = currentRouterURL.split('/'); // Split it into array

      // Remove the last url
    if ((currentRouterURL.length - 1) > 0) {
      currentRouterURL = currentRouterURL.splice(0, currentRouterURL.length - 1);
    }

    currentRouterURL = currentRouterURL.join('/'); // Form the relative path
  }

  return currentRouterURL;
};
