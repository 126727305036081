import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { GzIcon } from 'library/shared/widgets';
import './multiple-role.scss';

export default class TBLMultipleRole extends Component {

    static propTypes = {
        value: PropTypes.string,
        row: PropTypes.object,
        onRoleChange: PropTypes.func
    };

    render() {
        const { value, row, onRoleChange } = this.props;
        const { Option } = Select;

        const rolevalues = [
            {
                name: (
                    <div className='role-title'>
                        <GzIcon iconClassName='role-icon-image' name="user" />
                        <span>User</span>
                    </div>
                ),
                value: 'user',
                key: 'user'
            },
            {
                name: (
                    <div className='role-title'>
                        <GzIcon iconClassName='role-icon-image' name="edit" />
                        <span>Creator</span>
                    </div>
                ),
                value: 'creator',
                key: 'creator'
            },
            {
                name: (
                    <div className='role-title'>
                        <GzIcon iconClassName='role-icon-image' name="eye" />
                        <span>Admin</span>
                    </div>
                ),
                value: 'admin',
                key: 'admin'
            },
            {
                name: (
                    <div className='role-title'>
                        <GzIcon iconClassName='role-icon-image' name="lock" />
                        <span>SuperAdmin</span>
                    </div>
                ),
                value: 'superAdmin',
                key: 'superAdmin'
            }
        ];

        return (
            <div className='multiple-role-select-wrapper'>
                <Select
                    className='multiple-role-select'
                    bordered={false}
                    defaultValue={value}
                    onChange={(role) => { row.role = role; onRoleChange(row) }}>
                    {rolevalues.map(role =>
                        <Option key={role.key} value={role.value}>{role.name}</Option>
                    )}
                </Select>
            </div>
        );
    }
}

export { TBLMultipleRole };
