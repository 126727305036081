import React, { useState, Fragment, useEffect, useContext } from 'react';
import { GoogleLogout } from 'react-google-login';
import jwt from 'jsonwebtoken';
import * as Msal from 'msal';
import { Popover, notification, Tooltip } from 'antd';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { STORE_KEYS, STORE_ACTIONS, STORAGE } from 'library/common/constants';
import { GzIcon, GzDivider, GzButton } from 'library/shared/widgets';
import { useFirstRender } from 'library/shared/hooks';
import { StoreContext } from 'Store.context';
import {
  AuthenticationStore,
  onAuthenticationChange$,
  onUserAvatarChange$,
  onUserRoleChange$,
  onLogoutChange$,
  onEmailVerified$
} from '../authentication-route/AuthenticationRoute';
import { HttpService } from 'library/common/services';
import CobHeaderService from './cob.header.service';
import './cob.header.scss';
import { setLoader } from '../loader/Loader';
import { getAuthenticationTokens } from 'library/common/helpers';

export function CobHeader() {
    // Component hooks instance declarations
  const { storeDispatch } = useContext(StoreContext);
  const history = useHistory();
  const { path } = useRouteMatch();
    // States for the component
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(AuthenticationStore.isLoggedIn);
  const [userName, setUserName] = useState();
  const [userAvatar, setUserAvatar] = useState();
  const [isPopoverShow, setIsPopoverShow] = useState(false);
  const [currentUserDetails, setCurrentUserDetails] = useState({});
  const [isPopOverVisible, setIsPopOverVisible] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

    // Local state maintenance
  const subscriptions = [];
  const token = new URLSearchParams(useLocation().search).get("token");
    /* Handler for before component render */
  useFirstRender(() => {
    if (token) {
      AuthenticationStore.login(token);
      setIsUserLoggedIn(true);
      getCurrentUserInfo(true);
    } else {
      getCurrentUserInfo(isUserLoggedIn);
    }
  });
  const [thirdPartyAuth, setThirdPartyAuth] = useState();

  /* MSAL configuration */
  const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_MS_CLIENT_ID,
      redirectUri: process.env.REACT_APP_REDIRECT_UI_LOCAL
    }
  };

  const msalInstance = new Msal.UserAgentApplication(msalConfig);

    /* Handle component did mount */
  useEffect(() => {
        // Emitter to notify when there is a change in an authentication
    onAuthenticationChange$.subscribe(isLoggedIn => {
      setIsUserLoggedIn(isLoggedIn);
      getCurrentUserInfo(isLoggedIn);
    });

    onLogoutChange$.subscribe(isLoggedOut => {
      if (isLoggedOut) {
        history.push('/authentication/signin');
      }
    });

        // Emitter to notify when there is change in an user avatar
    onUserAvatarChange$.subscribe(imageUrl => {
      setUserAvatar(imageUrl);
      storeDispatch({ type: STORE_ACTIONS.USER_INFO, [STORE_KEYS.USER_INFO]: { imageUrl: imageUrl } });
    });

        // Unsubscribe all the listeners
    return () => subscriptions.forEach(s => s.unsubscribe());
  }, []);

    /* Get current user information from REST api */
  const getCurrentUserInfo = (isLoggedIn) => {
        // Get the user info only, if logged in
    if (isLoggedIn) {
      const authenticationTokens = getAuthenticationTokens();
      const authType = jwt.decode(authenticationTokens[STORAGE.AUTHENTICATION_TOKENS])?.thirdPartyAuth;
      setThirdPartyAuth(authType);
          // Is user verified is email?
      setLoader(true);
      CobHeaderService.isEmailVerified().then(res => {
        onEmailVerified$.next(!res.Data.EmailVerified);
        setIsEmailVerified(res.Data.EmailVerified);
        if (res?.Data?.CreatePwdEmailSent) {
          // history.push('/home');
          if (localStorage.getItem('loginType') && localStorage.getItem('loginType') === 'oAuth') {
            notification.info({
              message: 'Mail Verification',
              description: `Email has sent to ${res.Data.WorkEmail} to create an password`
            });
            localStorage.removeItem('loginType');
          }
        }
        if (res?.Data && !res?.Data?.EmailVerified) {
          history.push('/home/getting-started');
        }
      }).catch(err => {
        console.error(err);
        setLoader(false);
        // onEmailVerified$.unsubscribe();
      });
      CobHeaderService.getUserProfile().then(response => {
        if (response?.Data) {
          storeDispatch({ type: STORE_ACTIONS.USER_INFO, [STORE_KEYS.USER_INFO]: response?.Data });
          setCurrentUserDetails(response?.Data);
          setUserName(`${response?.Data.FirstName}`);
          setUserAvatar(response?.Data.ImageUrl);
          onUserRoleChange$.next(response?.Data.UserRole);
          setLoader(false);
        }
      }).catch(error => {
        history.push('/authentication/signin');
        console.error(error);
        setLoader(false);
        // onUserRoleChange$.unsubscribe();
      });
    }
  };

    /* Navigate it into profile page */
  const onViewProfile = () => {
    storeDispatch({ type: STORE_ACTIONS.APP_GO_BACK_URL, [STORE_KEYS.APP_GO_BACK_URL]: path });
    history.push('/home/profile');
  };

    /* Logout the user and navigate it into the authentication page */
  const onLogout = () => {
    AuthenticationStore.logout();
    history.push('/authentication/signin');
  };

    /* Navigate to the home module when home gets clicked */
  const onClickHome = () => {
    history.push('/home');
  };

  const infusionsoftAPI = (type, uri) => {
    const data = {
      Firstname: currentUserDetails?.FirstName,
      Lastname: currentUserDetails?.LastName,
      Email: currentUserDetails?.WorkEmail,
      Organization: currentUserDetails?.Organization,
      Phone: '',
      // eslint-disable-next-line no-nested-ternary
      Tag: type === 'Tableau' ? 1651 : type === 'PowerBI' ? 1657 : 1663,
      Product: type,
      country_code: '',
      country_name: ''
    };

    fetch('https://dev.biconnector.com/form-data/tableau-data.php', {
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      body: new URLSearchParams(Object.keys(data).map(key => [key, data[key]]))
    }).then(response => response.json()).then(json => {
      console.log({ json });
    });


    var link = document.createElement("a");
    link.download = type;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  /* Download Menu elements */
  const DownloadMenu = (
    <div className="download-popover">
      <p className="download-title">BI Connector for Desktop</p>
      <ul className="download-list">
        <li className="download-list-item" onClick={() => infusionsoftAPI('Tableau', process.env.REACT_APP_TABLEAU)}>
          <GzIcon name="ic_tableau" /><span>Tableau</span>
        </li>
        <li className="download-list-item" onClick={() => infusionsoftAPI('PowerBI', process.env.REACT_APP_POWER_BI)}>
          <GzIcon name="ic_power_bi" /><span>PowerBI</span>
        </li>
        <li className="download-list-item" onClick={() => infusionsoftAPI('Qlik', process.env.REACT_APP_QLIK)}>
          <GzIcon name="ic_qlik" /><span>Qlik</span>
        </li>
      </ul>
      <GzDivider />
      <p className="subtitle">Looking for Server Edition?</p>
      <div className="flex-center">
        <a
          className="contact-us-button"
          target="_blank"
          rel="noreferrer"
          href="https://dev.biconnector.com/request-quote/"
        >Contact us</a>
        {/* <GzButton name="contact us" type="default" className="contact-us-btn"/> */}
      </div>
    </div>
  );

    // Profile menu elements
  const profileMenu = (
    <div className="profile-menu">
      <div className="menu-avatar">
        <div className="avatar-image">
          {userAvatar ?
            (<img src={userAvatar} alt="Current Org" />) :
            (<span className="fallback"> {currentUserDetails.FirstName?.[0] + currentUserDetails.LastName?.[0]} </span>)
          }
        </div>
        <div className="avatar-details">
          <h5 className="username">
            {currentUserDetails.FirstName + " " + currentUserDetails.LastName}
          </h5>
          <h5 className="email">{currentUserDetails.WorkEmail}</h5>
          <ul>
            { isEmailVerified &&
                <li onClick={() => { setIsPopOverVisible(false); onViewProfile(); }}>View Profile</li>
            }
            { thirdPartyAuth === "Google" &&
              <li>
                <GoogleLogout
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  buttonText="Logout"
                  onLogoutSuccess={() => { setIsPopOverVisible(false); onLogout(); }}
                  render={renderProps => (
                    <span onClick={renderProps.onClick}>Sign out</span>
                  )}
                />
              </li>
            }

            { thirdPartyAuth === 'Microsoft' &&
              <li onClick={() => {setIsPopOverVisible(false); msalInstance.logout(); onLogout(); }}>Sign out</li>
            }
            { !thirdPartyAuth &&
              <li onClick={() => {setIsPopOverVisible(false); onLogout(); }}>Sign out</li>
            }
          </ul>
        </div>
      </div>
      <GzDivider className="profile-divider" />
      <div className="menu-footer">
        <ul>
          <li>
            <a
              className="site-url"
              href="https://www.biconnector.com/about"
              target="_blank"
              rel="noreferrer"
            >
              About
            </a>
          </li>
          <li>
            <a
              className="site-url"
              href="https://www.biconnector.com/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              className="site-url"
              href="https://www.biconnector.com/terms-and-conditions/"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>
          </li>
        </ul>
      </div>
    </div>
  );


    /* Render the JSX elements */
  return (
    <Fragment>
      {isUserLoggedIn &&
        <header>
          <section className="system-bar">
            <div className="logo-block" onClick={onClickHome}>
              <GzIcon name="logo" />
            </div>
            <nav className="navbar">
              <ul>
                { currentUserDetails.UserRole !== 'SuperAdmin' &&
                  <Fragment>
                    <li>
                      <Popover
                        overlayClassName="download-menu"
                        placement="bottom"
                        content={DownloadMenu}
                        trigger="click"
                        visible={isPopoverShow}
                        onVisibleChange={(isVisible) => setIsPopoverShow(isVisible) }
                      >
                        <div
                          aria-hidden="true"
                          role="button"
                          className={`download-button ${isPopoverShow ? 'active' : ''}`}
                        >Download BI Connector</div>
                      </Popover>
                    </li>
                    <li>
                      <a
                        className="site-url"
                        href="https://www.biconnector.com/getting-started-with-bi-connector-for-tableau/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Documentation
                      </a>
                    </li>
                    <li>
                      Need Help?&nbsp;
                      <a
                        className="site-url"
                        href="https://www.biconnector.com/contact-us/"
                        target="_blank"
                        rel="noreferrer">Contact us</a>
                    </li>
                    <li className="less-margin-left"><GzDivider orientation="center" type="vertical" /></li>
                  </Fragment>
                }
                <li>
                  <Popover
                    overlayClassName="header-profile-menu-popover"
                    placement="bottomRight"
                    content={profileMenu}
                    trigger="click"
                    visible={isPopOverVisible}
                    onVisibleChange={(isVisible) => setIsPopOverVisible(isVisible) }
                  >
                    { userAvatar ?
                      <Tooltip title={userName}>
                        <span className="avatar"><img src={userAvatar} alt="Avatar" /></span>
                      </Tooltip> :
                      <Tooltip title={userName}>
                        { currentUserDetails?.FirstName ?
                          <span className="avatar user-name">{currentUserDetails?.FirstName?.[0] + currentUserDetails?.LastName?.[0]}</span> :
                          <span className="avatar user-name" />
                        }
                      </Tooltip>
                    }
                  </Popover>
                </li>
              </ul>
            </nav>
          </section>
        </header>
      }
    </Fragment>
  );
}
