import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';

class GzPopover extends PureComponent {
   static propTypes = {
     content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
     title: PropTypes.string,
     placement: PropTypes.string,
     trigger: PropTypes.string,
     children: PropTypes.node
   }

   static defaultProps = {
     content: null,
     title: '',
     placement: '',
     trigger: '',
     children: null
   }

   render() {
     const {
       content,
       title,
       placement,
       trigger,
       children
     } = this.props;
     return (
       <Popover
         content={content}
         title={title}
         placement={placement}
         trigger={trigger}>
         {children}
       </Popover>
     );
   }
}

export default GzPopover;
