import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import { COMPONENT_NAMES } from '../constants';
import { onChangeSelectedRows } from '../events';

export function GuidanzTableBatchActions(props) {
	// Props from the parent component
	const { children, onClearAll } = props;

	// States for the comoponent
	const [selectedRows, setSelectedRows] = useState([]);

	// Local state maintenance
	const subscriptions = [];

	/* Handler for listen the event */
	useEffect(() => {
		subscriptions.push(onChangeSelectedRows().subscribe(rows => setSelectedRows(rows)));

		// Unsubscribe the listeners
		return () => subscriptions.forEach(s => s.unsubscribe());

		// eslint-disable-next-line
	}, []);

	/* Render the JSX elements */
	return (
		<div className={`table-batch-actions-wrapper ${selectedRows.length ? 'batch-actions-active' : ''}`}>
			<div className='row-details'>
				<span>
					{selectedRows.length} {selectedRows.length === 1 ? 'row' : 'rows'} selected
				</span>
				<Button
					className='clear-button'
					type='link'
					onClick={() => {
						onClearAll();
						setSelectedRows([]);
					}}
				>
					Clear All
				</Button>
			</div>

			<div className='batch-actions'>{children}</div>
		</div>
	);
}

GuidanzTableBatchActions.propTypes = {
	onClearAll: PropTypes.func,
	children: PropTypes.element
};

GuidanzTableBatchActions.defaultProps = {
	onClearAll: () => {}
};

GuidanzTableBatchActions.displayName = COMPONENT_NAMES.GuidanzTableBatchActions;
