import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';


export default class GzTabs extends PureComponent {
   static propTypes = {
     animated: PropTypes.bool,
     defaultActiveKey: PropTypes.string,
     hideAdd: PropTypes.bool,
     size: PropTypes.string,
     tabBarExtraContent: PropTypes.element,
     tabBarGutter: PropTypes.number,
     tabPosition: PropTypes.string,
     type: PropTypes.string,
     onChange: PropTypes.func,
     onEdit: PropTypes.func,
     onTabClick: PropTypes.func,
     tabItem: PropTypes.array,
     className: PropTypes.string
   }

   static defaultProps = {
     animated: true,
     defaultActiveKey: '',
     hideAdd: false,
     size: 'default',
     tabBarExtraContent: null,
     tabBarGutter: 0,
     tabPosition: 'top',
     type: 'line',
     onChange: () => { },
     onEdit: () => { },
     onTabClick: () => { },
     tabItem: [],
     className: ''
   }

   render() {
     const {
       animated,
       defaultActiveKey,
       size,
       tabBarExtraContent,
       tabBarGutter,
       tabPosition,
       type,
       onChange,
       onEdit,
       onTabClick,
       tabItem,
       hideAdd,
       className
     } = this.props;
     const { TabPane } = Tabs;
     return (
       <React.Fragment>
         <Tabs
           defaultActiveKey={defaultActiveKey}
           onChange={onChange}
           animated={animated}
           hideAdd={hideAdd}
           size={size}
           tabBarExtraContent={tabBarExtraContent}
           tabBarGutter={tabBarGutter}
           tabPosition={tabPosition}
           type={type}
           onEdit={onEdit}
           onTabClick={onTabClick}
           className={className}
         >
           {tabItem.map(pane => (
             <TabPane
               tab={pane.title}
               key={pane.key}
               closable={pane.closable}
             >
               {pane.content}
             </TabPane>
           ))}
         </Tabs>
       </React.Fragment>
     );
   }
}
