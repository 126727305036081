import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from 'antd';
import momentPropTypes from 'react-moment-proptypes';

export default class GzTimePicker extends PureComponent {
  static propTypes = {
    // children: PropTypes.element,
    format: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    open: PropTypes.bool,
    onOpen: PropTypes.func,
    defaultValue: momentPropTypes.momentObj,
    value: momentPropTypes.momentObj,
    children: PropTypes.node
  }

  static defaultProps = {
    defaultValue: null,
    format: 'HH:mm',
    onChange: () => { },
    placeholder: 'Select a time',
    open: false,
    onOpen: () => { },
    value: '',
    children: null
  }

  render() {
    const {
      defaultValue,
      format,
      onChange,
      placeholder,
      open,
      onOpen,
      value,
      children
    } = this.props;
    return (
      <TimePicker
        open={open}
        format={format}
        placeholder={placeholder}
        onOpenChange={onOpen}
        onChange={onChange}
        renderExtraFooter={() => children || null}
        defaultValue={defaultValue}
        value={value}
      />
    );
  }
}
