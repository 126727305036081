import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';

class GzDrawer extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    placement: PropTypes.string,
    closable: PropTypes.bool,
    onClose: PropTypes.func,
    visible: PropTypes.bool,
    children: PropTypes.element,
    getContainer: PropTypes.bool
  }

  static defaultProps = {
    title: '',
    placement: '',
    closable: true,
    onClose: () => { },
    visible: false,
    children: null,
    getContainer: false
  }

  render() {
    const {
      title,
      placement,
      closable,
      onClose,
      visible,
      children,
      getContainer
    } = this.props;
    return (
      <Drawer
        title={title}
        placement={placement}
        closable={closable}
        visible={visible}
        onClose={onClose}
        getContainer={getContainer}
        style={{ position: 'absolute' }}
      >
        {children}
      </Drawer>
    );
  }
}


export {
  GzDrawer
};
