import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

class GzTable extends PureComponent {
  static propTypes = {
    rowSelection: PropTypes.object,
    columns: PropTypes.array,
    dataSource: PropTypes.array,
    onChange: PropTypes.func,
    bordered: PropTypes.bool,
    loading: PropTypes.bool,
    // rowKey: PropTypes.string,
    scroll: PropTypes.bool,
    pagination: PropTypes.object,
    size: PropTypes.string,
    hasData: PropTypes.bool,
    tableLayout: PropTypes.string,
    classname: PropTypes.string
  }

  static defaultProps = {
    rowSelection: {},
    columns: [],
    dataSource: [],
    loading: false,
    onChange: () => { },
    scroll: false,
    pagination: { position: ['bottomRight'] },
    size: '',
    hasData: false,
    tableLayout: '',
    bordered: false,
    classname: ''
  }

  render() {
    const {
      rowSelection,
      columns,
      dataSource,
      loading,
      onChange,
      scroll,
      pagination,
      size,
      hasData,
      tableLayout,
      bordered,
      classname
    } = this.props;

    return (
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        onChange={onChange}
        scroll={scroll}
        pagination={pagination}
        size={size}
        tableLayout={tableLayout}
        hasData={hasData}
        bordered={bordered}
        className={classname}
      />
    );
  }
}
export default GzTable;
